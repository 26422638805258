import Vue from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import "./assets/main.css";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";
import animate from "animate.css";
import "wowjs/css/libs/animate.css";
// 引入全局css
import "./assets/scss/style.scss";
Vue.use(Antd);
Vue.use(dataV);
import Echart from "./components/echart/index.vue";
import Reacquire from "./components/reacquire/reacquire.vue";

// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";

//引入echart
//4.x 引用方式
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

// // 全局注册
Vue.component("icon", Icon);

Vue.component("Echart", Echart);
Vue.component("Reacquire", Reacquire);

new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
