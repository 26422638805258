var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  border: '1px solid #ebedf0',
  borderRadius: '2px',
  textAlign: 'center',
  background: '#fafafa',
})},[_c('div',{staticStyle:{"width":"100%","height":"100%","overflow":"hidden"},attrs:{"id":"container"}},[_c('div',{staticClass:"setBtn",on:{"click":_vm.showDrawer}},[_c('div',[_vm._v("模")]),_c('div',[_vm._v("型")]),_c('div',[_vm._v("设")]),_c('div',[_vm._v("置")])]),_c('div',{staticClass:"model_name"},[_vm._v(" "+_vm._s(_vm.readyStateNmae)+" ")])]),_c('a-drawer',{attrs:{"title":"3D 模型设置","placement":"left","visible":_vm.visible,"mask":false,"get-container":false,"wrap-style":{ position: 'absolute' }},on:{"close":_vm.onClose}},[_c('div',{staticClass:"form_box"},[_c('div',{staticClass:"item_btn"},[_vm._v(" 自动旋转 "),_c('a-switch',{attrs:{"default-checked":""},on:{"change":_vm.revolvebtn}})],1),_c('div',{staticClass:"item_btn"},[_vm._v(" 显示网格 "),_c('a-switch',{on:{"change":_vm.showGridding}})],1),_c('div',{staticClass:"item_btn"},[_vm._v(" 显示尺寸 "),_c('a-switch',{attrs:{"default-checked":""},on:{"change":_vm.showSizi}})],1),_c('div',{staticClass:"item_Split"},[_vm._v(" 模型拆分 "),_c('a-slider',{attrs:{"min":0,"max":100},on:{"change":_vm.onSplit},model:{value:(_vm.inputValue1),callback:function ($$v) {_vm.inputValue1=$$v},expression:"inputValue1"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }