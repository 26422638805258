<template>
  <div>
    <Nav></Nav>
    <div class="navimg">
      <div class="box">
        <div class="box_boxtext">
          <p class="P1">联系我们</p>
          <p class="P2" id="P2">我们将及时为您提供专业的咨询服务</p>
        </div>
      </div>
    </div>

    <div class="walknav">
      <div class="box">华南机械 > 联系我们</div>
    </div>

    <div :class="conent" id="conent">
      <div class="callme_box box1">
        <div data-wow-delay="0.2s" class="wow animate__fadeInRight boxtext">
          <h2>华南机械制造有限公司</h2>
          <h4 style="color: red">——</h4>
          <p>广州市天河区凤凰街广汕二路13号236房</p>
          <p>联系人员：李先生</p>
          <p>服务热线：020-87066918</p>
          <p>企业邮箱：southchina1688@163.com</p>
        </div>
      </div>
      <div class="callme_box box2">
        <div data-wow-delay="0.2s" class="wow animate__fadeInRight boxtext">
          <h2>华南机械制造有限公司</h2>
          <h4 style="color: red">——</h4>
          <p>佛山市南海区夏西工业区横路13号</p>
          <p>联系人员：李先生</p>
          <p>服务热线：020-87066918</p>
          <p>企业邮箱：southchina1688@163.com</p>
        </div>
      </div>
    </div>

    <callme data-wow-delay="0.2s" class="wow animate__fadeInUp callme"></callme>
    <FTrecord data-wow-delay="0.2s" class="wow animate__fadeInUp"></FTrecord>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import NewsInquiry from "../components/NewsInquiry.vue";
import callme from "../components/callme.vue";
import "wowjs/css/libs/animate.css";
import { WOW } from "wowjs";
export default {
  components: {
    Nav: Nav,
    // 结尾
    FTrecord: FTrecord,
    // 新闻资讯
    NewsInquiry: NewsInquiry,
    callme: callme,
  },
  data() {
    return {
      conent: "",

      callme: "true",
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateClassName); // 监听窗口大小变化
    this.updateClassName(); // 初始化时更新类名

    this.$nextTick(() => {
      new WOW().init();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateClassName); // 移除窗口大小变化的监听器
  },
  methods: {
    updateClassName() {
      this.conent = window.innerWidth > 1200 ? "conent" : "conent_conmin";

      var P2 = document.querySelector("#P2");
    },
  },
};
</script>

<style scoped>
.navimg {
  width: 100vw;
  height: 260px;
  /* background-color: red; */
  background-image: url(../assets/华南官网2.0素材/联系我们/矩形\ 3.png);
  background-size: 100% 100%;
}

.navimg .box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  /* background-color: red; */
}

.navimg .box .box_boxtext {
  width: 100%;
  height: 30%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.navimg .box .box_boxtext .P1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  /* text-align: left; */
  color: #ffffff;
  /* line-height: 77px; */
  letter-spacing: 2.4px;
  padding-bottom: 25px;
}

.navimg .box .box_boxtext .P2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  /* text-align: left; */
  color: #ffffff;
  line-height: 0px;
}

.walknav {
  width: 100vw;
  height: 50px;
  background-color: #f4f4f4;
}

.walknav .box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 50px;
}

.conent {
  width: 60vw;
  height: 324px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
}

.callme_box {
  position: relative;
  width: 49%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.2s;
}

.callme_box:hover {
  box-shadow: 4px 4px 6px rgba(98, 97, 97, 0.5);
}

.box1 {
  background-image: url(../assets/images/nhjd/联系我们/华南.png);
  background-size: cover;
  /* 让背景图片完全覆盖盒子 */
  background-position: right center;
  /* 将背景图片居中 */
}

.boxtext {
  width: 100%;
  height: 70%;
  /* background-color: red; */
  margin-left: 35px;
  margin-top: 55px;
  line-height: 35px;
}

.box2 {
  background-image: url(../assets/images/nhjd/联系我们/生产基地.png);
  background-size: cover;
  /* 让背景图片完全覆盖盒子 */
  background-position: right center;

  /* 将背景图片居中 */
}

.boxtext {
  width: 100%;
  height: 70%;
  /* background-color: red; */
  margin-left: 35px;
  margin-top: 55px;
  line-height: 35px;
}

/* --- */

.navimg_min {
  width: 100vw;
  height: 130px;
  background-image: url(../assets/华南官网2.0素材/联系我们/矩形\ 3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.navimg_min .box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.navimg_min .box .boxtext {
  width: 100%;
  height: 30%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.navimg_min .boxtext .P1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  /* line-height: 77px; */
  letter-spacing: 2.4px;
}

.navimg_min .boxtext .P2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 50px;
}

/* --- */

.conent_conmin {
  width: 95vw;
  /* height: 324px; */
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  /* .callme_box {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
    transition: box-shadow 0.2s;
    margin-bottom: 15px;
  } */

  /* .callme_box:hover {
    box-shadow: 4px 4px 6px rgba(98, 97, 97, 0.5);
  } */

  /* .box1 {
    background-image: url(../assets/images/nhjd/联系我们/华南.png);
    background-size: cover;
    background-position: right center;
    background-size: 100% 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    .boxtext {
      width: 100%;
      height: 70%;
      margin-left: 35px;
      line-height: 35px;
    }
  } */

  /* .box2 {
    background-image: url(../assets/images/nhjd/联系我们/生产基地.png);
    background-size: cover;
    background-position: right center;
    padding-top: 25px;
    padding-bottom: 25px;

    .boxtext {
      width: 100%;
      height: 70%;
      margin-left: 35px;
      line-height: 35px;
    }
  } */
}

.conent_conmin .callme_box {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.2s;
  margin-bottom: 15px;
}

.conent_conmin .callme_box:hover {
  box-shadow: 4px 4px 6px rgba(98, 97, 97, 0.5);
}

.conent_conmin .box1 {
  background-image: url(../assets/images/nhjd/联系我们/华南.png);
  background-size: cover;
  /* 让背景图片完全覆盖盒子 */
  background-position: right center;
  /* 将背景图片居中 */
  background-size: 100% 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.conent_conmin .box1 .boxtext {
  width: 100%;
  height: 70%;
  /* background-color: red; */
  margin-left: 35px;
  /* margin-top: 55px; */
  line-height: 35px;
}

.conent_conmin .box2 {
  background-image: url(../assets/images/nhjd/联系我们/生产基地.png);
  background-size: cover;
  /* 让背景图片完全覆盖盒子 */
  background-position: right center;
  /* 将背景图片居中 */
  padding-top: 25px;
  padding-bottom: 25px;
}

.conent_conmin .box2 .boxtext {
  width: 100%;
  height: 70%;
  /* background-color: red; */
  margin-left: 35px;
  /* margin-top: 55px; */
  line-height: 35px;
}
@media screen and (max-width: 821px) {
  .navimg {
    width: 100%;
    height: 80px;
  }

  .box_boxtext {
    height: 100%;
  }

  .box_boxtext .P1 {
    text-align: center;
    line-height: 45px;
  }

  .box_boxtext .P2 {
    display: none;
  }

  .walknav {
    width: 95%;
    margin: 0 auto;
  }

  .walknav .box {
    width: 100%;
    text-align: left;
  }

  .callme {
    padding-bottom: 75px;
  }
}
</style>
