<template>
  <div class="box">
    <!-- {{ $route.params.content }} -->
    <!-- <Model :readyState="$route.params.content"></Model> -->
    <iframe :src="$route.query.id"></iframe>
  </div>
</template>

<script>
import Model from "../components/3DModel.vue";

export default {
  // props: ["readyState"],
  components: {
    Model
  },
  created() {
    console.log(this.$route.query.id, 999999);
  }

}
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100vh;
  background-color: #555555;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>