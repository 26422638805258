<template>
  <div class="proDet">
    <Nav></Nav>
    <div class="navimg"></div>
    <div class="navbox">
      <div class="navbox_box">
        华南机械 > 产品中心 > {{ this.readyState.name }}
      </div>
    </div>

    <!--  -->
    <div class="FullColumn">
      <div class="ProductCrat">
        <div class="ProductCratLfet" v-on:mouseover="move" v-on:mouseout="onMouseOut" ref="productimgbox">
          <div class="LfetImg" ref="productimg"><img style="width: 100%;" :src="this.readyState.img" alt="" /></div>
          <div ref="mybox" v-on:click="click"
            style="width:100%;height:100%;background-color:rgb(32, 104, 207);cursor: pointer;;position:absolute;z-index:100;opacity: 0.5;top:0;display:none;">
            <span style="position:absolute;top:48%; color:#ffffff;font-size:20px;left:42%;">点击查看3D</span>
          </div>
        </div>
        <div class="ProductCratLfetbox" ref="ifrbox">
          <iframe style="width:107.5%;height:102%;border:0px;position:absolute;top:0;left:-6.5%"
            :src="this.readyState.model"></iframe>
          <!-- <Model :readyState="readyState" v-else ></Model> -->
        </div>
        <div class="ProductCratRight">
          <div class="RightTitle">
            <p class="RightTitleP1">{{ this.readyState.name }}</p>

            <p class="RightTitleP2">{{ this.readyState.peculiarity }}</p>

            <p class="RightTitleP3">{{ this.readyState.Scope }}</p>
            <div class="RightTitleImg">
              <img src="../assets/华南官网2.0素材/产品详情/20230916104529.png"></img>
            </div>
            <div class="RightTitleImgbut RightTitleImgbutand">
              <div class="item item1">
                <img style="
                 
                          width: 25px;
                          height: 60%;
                          position: relative;
                          top: 50%;
                          transform: translateY(-50%);
                        " src="../assets/华南官网2.0素材/产品详情/bofan.png" />&nbsp; &nbsp;观看视频
              </div>
              <div class="item item2">
                <img style="
                          width: 25px;
                          height: 60%;
                          position: relative;
                          top: 50%;
                          transform: translateY(-50%);
                        " src="../assets/华南官网2.0素材/产品详情/liaot.png" />&nbsp; &nbsp;立即咨询
              </div>
              <div class="item item3" @click="hono">
                <div class="item3_box1">24小时免费咨询热线</div>
                <div class="item3_box2">
                  <img style="width: 100%" src="../assets/华南官网2.0素材/产品详情/call.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品详情 -->
      <div class="xiangqing">
        <div class="xiangqing_lfet">
          <p class="leftP1">{{ this.readyState.name }}</p>
          <p class="leftP2">
            {{ this.readyState.Description }}
          </p>
        </div>
        <div class="xiangqing_right">
          <div class="rightNav">相关产品</div>
          <div class="rightabBut">
            <div class="Butimg">
              <img v-if="img1" style="width: 120%;height:100%" src="../assets/华南官网2.0素材/首页产品/破碎/PE颚式破碎机.png" alt="">
              <img v-if="img2" style="width: 120%;height:100%" src="../assets/华南官网2.0素材/首页产品/破碎/PF反击式破碎机.png" alt="">
              <img v-if="img3" style="width: 120%;height:100%" src="../assets/华南官网2.0素材/首页产品/破碎/PY弹簧圆锥破碎机.png" alt="">
            </div>
            <ol class="Butol">
              <li v-for="(item, index) in but" :key="index" @click="butol(index)"></li>
            </ol>
          </div>
        </div>
      </div>

      <!-- 3D展示 -->
      <div class="Mine3D">
        <div class="Mine3D_nav">
          <div class="kongbox"></div>
          <div class="navTitle">设备3D展示</div>
          <div class="fullScreen" @click="goModel">

            <span> 全</span>
            <span>屏</span>
            <a-icon type="fullscreen" style="font-size: 30px; margin-left: 8px;" />

          </div>



        </div>

        <div class="Mine3D_but">
          <!-- :src="this.result[0].productmodel" -->
          <!-- <iframe style="width: 100%; height: 100%" frameborder="0" :src="this.readyState.model"></iframe> -->
          <Model :readyState="readyState"></Model>
        </div>
      </div>

      <!-- 产品详情 -->
      <div class="details">
        <!-- <div class="details_nav">
          <div class="details_kongbox"></div>
          <div class="details_navTitle">产品详情</div>
        </div> -->
        <div class="details_but">
          <div class="image-container">

            <img :src="this.readyState.xiangq" style="background-color: #f2f2f2;opacity: 0.6; /* 设置透明度为50% */" alt=""
              srcset="" />
          </div>
        </div>
      </div>
    </div>
    <FTrecord class="FTrecord"></FTrecord>
  </div>
</template>

<script>
import Nav from "../components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import callme from "../components/callme.vue";
import Model from "../components/3DModel.vue";
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons-vue';



// import axios from "axios";

export default {
  // props:['id','']
  components: {
    Nav: Nav,
    FTrecord: FTrecord,
    callme: callme,
    Model,
    StarOutlined

  },
  data() {
    return {
      crushText: "",
      crushId: "",
      readyState: [],
      result: [],
      but: [{ id: 1 }, { id: 2 }, { id: 3 }],
      img1: true,
      img2: false,
      img3: false
    };
  },
  watch: {
    "$route.params.crushText": function (news, old) {
      this.sendRequest();
    },
  },
  mounted() {

  },
  beforeMount() {

  },
  created() {
    this.sendRequest();

  },
  methods: {
    goModel() {
      // console.log(this.readyState);
      // this.$router.push('/modelDetails')
      this.$router.push({
        name: 'modelDetails',
        query: {
          id: this.readyState.model,
        }
      })
    },
    sendRequest() {
      console.log(this.$route.params, 888888);
      this.crushText = this.$route.params.crushText;
      this.crushId = this.$route.params.id;

      var xhr = new XMLHttpRequest();
      xhr.open('GET', './product_detalis.json');
      xhr.onreadystatechange = () => {
        console.log(xhr, 1111);
        if (xhr.readyState === 4 && xhr.status === 200) {
          var res = JSON.parse(xhr.responseText)
          const productid = parseInt(localStorage.getItem('productid'))
          console.log(res, productid, 222222);
          this.readyState = res.detailsImg[productid];
        }
      }
      xhr.send()

    },
    butol(index) {
      if (index == 0) {
        this.img1 = true
        this.img2 = this.img3 = false
      } else if (index == 1) {
        this.img2 = true
        this.img1 = this.img3 = false
      } else if (index == 2) {
        this.img3 = true
        this.img1 = this.img2 = false
      }
    },
    move() {
      console.log(this.$refs.mybox);
      this.$refs.mybox.style.display = 'block';
    },
    onMouseOut() {
      this.$refs.mybox.style.display = 'none';
    },
    click() {
      if (this.readyState.showModel) {
        return
      }
      this.$refs.productimgbox.style.display = 'none';
      this.$refs.ifrbox.style.display = 'block';
    },

    hono() {
      location.href = "tel:020-87066918"; // 拨打123456789
    }
  },
  beforeDestroy() { },
};
</script>

<style scoped lang="less">
.fullScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  cursor: pointer;
  margin-right: 10px;
}

.navimg {
  width: 100vw;
  height: 230px;
  background-image: url(../assets/华南官网2.0素材/产品详情/banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* navbox */
.navbox {
  width: 100vw;
  height: 50px;
  background-color: #f4f4f4;
}

.navbox_box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 50px;
}


.FullColumn {
  width: 60vw;
  margin: auto;
}


/* ProductCrat */
.ProductCrat {
  width: 100%;
  height: 385px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 50px;
  margin-bottom: 50px;
}

.ProductCratLfet {
  width: 50%;
  height: 100%;
  border: none;
  position: relative;
}

.ProductCratLfetbox {
  width: 50%;
  height: 100%;
  border: none;
  position: relative;
  display: none;
  overflow: hidden;
  background-image: url("../assets/shuiyin.png");
}

.ProductCratRight {
  width: 45%;
  height: 100%;
  margin-left: 20px;
}

.ProductCratLfet .LfetImg {
  width: 250px;
  height: 100%;
  margin: 75px auto;
  /* margin-left:50px; */
}

.ProductCratLfet .LfetImg .img {
  width: 120%;
  height: 100%;

}

.ProductCratRight {
  width: 45%;
  height: 100%;
}

.RightTitle {
  width: 100%;
  height: 100%;
  float: right;
}

.RightTitleP1 {
  display: block;
  width: 100%;
  height: 45px;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #000000;
  line-height: 23px;
  border-bottom: #ccc 1px solid;
  /* background-color: red; */
  /* margin-bottom: /; */
}

.RightTitleP2 {
  display: block;
  width: 100%;
  height: 100px;
  line-height: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  border-bottom: 1px solid #ccc;
  /* background: bisque; */
  line-height: 25px;
  /* margin-top: 15px; */
  margin-bottom: 25px;
  /* background-color: red; */
  margin-top: 15px;
}

.RightTitleP3 {
  display: block;
  width: 100%;
  height: 35px;
  line-height: 20px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Regular;
  font-weight: 400;
  text-align: left;
  color: #221815;
  /* background: saddlebrown; */
}

.RightTitleImg {
  width: 300px;
  height: 52px;

}

.item {
  width: 75px;
  height: 100%;
  background-color: palevioletred;
}

.RightTitleImgbut {
  /* width: 100%; */
  /* height: 52px; */
  display: flex;
  justify-content: left;
  margin-top: 40px;


}

.item {
  width: 130px;
  height: 42px;
  background-color: red;
  line-height: 42px;
  padding-left: 10px;
  color: aliceblue;
  margin-right: 10px;
}

.item3 {
  width: 130px;
  height: 42px;
  background: none;
  padding-left: none;


}

.item3_box1 {
  width: 100%;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  color: black;
}

.item3_box2 {
  width: 100%;
  height: 30px;
  line-height: 30px;
}




/*  */
.xiangqing {
  width: 100%;
  height: 465px;
  /* background-color: black; */
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  /* background-color: #5f2060; */
}

.xiangqing_lfet {
  width: 55%;
  height: 55%;
}

.leftP1 {
  height: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #cf000e;
  line-height: 21px;
}

.leftP2 {
  width: 100%;
  height: 79px;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  line-height: 30px;
}

.xiangqing_right {
  width: 23%;
  height: 75%;
  border: 10px solid #cccccc;
  overflow: hidden;
  /* background-color:red; */
}

.rightNav {
  position: relative;
  width: 100%;
  height: 35px;
  background-color: red;
  text-align: center;
  line-height: 35px;
  color: black;
  font-size: 18px;
}

.rightabBut {
  position: relative;
  width: 100%;
  height: 100%;
}

.Butimg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-65%, -60%);
  width: 50%;
  height: 50%;
}

.Butol {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  width: 100px;
  height: 25px;

  /* background-color: rgb(250, 250, 250); */
  display: flex;
  justify-content: space-between;
}

.Butol li {
  width: 25px;
  height: 100%;
  background-color: rgb(141, 138, 138);
  float: left;
  border-radius: 50%;
  list-style-type: none;
}


/* 3D展示 */
.Mine3D {
  width: 100%;
  height: 500px;

}

.Mine3D_nav {
  width: 100%;
  height: 42px;
  background: #f2f2f2;
  display: flex;
  justify-content: left;
}

.kongbox {
  width: 8px;
  height: 100%;
  background: #cf000e;
}

.navTitle {
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #000000;
  line-height: 42px;
  padding-left: 10px;
}

.Mine3D_but {
  width: 100%;
  height: 100%;
  background-color: #cccc;
}


/*  <!-- 产品详情 --> */
.details {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

}

.details_nav {
  width: 100%;
  height: 42px;
  background: #f2f2f2;
  display: flex;
  justify-content: left;
}

.details_kongbox {
  width: 8px;
  background: #cf000e;
}

.details_navTitle {
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #000000;
  line-height: 42px;
  padding-left: 10px;
}

.details_but {
  width: 100%;
}

.image-container {
  width: 100%;

}

.image-container img {
  width: 100%;
}

iframe html body img {
  width: 100%;
}

@media all and (max-width: 821px) {

  /* nav */
  .navimg {
    height: 125px;
  }

  .navbox {}

  .navbox_box {
    width: 95%;
    text-align: left;
    padding: 0;
  }

  /* FullColumn */
  .FullColumn {
    width: 95%;

  }

  /* 1 */
  .ProductCrat {
    display: flex;
    height: auto;
    justify-content: space-between;
  }

  .ProductCratLfet {
    width: 100%;
    margin-top: 0;
    padding-top: 0;
  }

  .ProductCratLfet .LfetImg {
    margin: 0 auto;
  }

  .ProductCratLfetbox {
    width: 100%;
  }

  .ProductCratRight {
    width: 100%;
    padding-top: 15px;
    overflow: auto;
  }

  .RightTitleP1 {
    text-align: center;
  }

  .RightTitleP2 {}

  .RightTitleImg {
    margin: 0 15px;
  }

  .item3_box1 {
    font-size: 10px;
  }

  .item1,
  .item2 {
    font-size: 14px;
  }

  /* xiangqing */
  .xiangqing {
    width: 95%;
  }

  .xiangqing_lfet {
    width: 100%;
    /* background-color:red; */
  }

  .xiangqing_lfet .leftP2 {
    font-size: 16px;
  }

  .xiangqing_right {
    display: none;
  }


  /* Mine3D */
  .Mine3D {}

  /* details */
  .details {}


  /* FTrecord */
  .FTrecord {}
}
</style>
