<template>
  <div>
    <!-- 网关-->

    <div class="ending">
      <div class="xt"></div>
      <div class="endingBox">
        <div class="top">
          <div class="left">
            <table class="tab1">
              <td class="tab1td" >关于华南</td>
              <td class="tab1td">推荐产品</td>
              <td class="tab1td">解决方案</td>
              <td class="tab1td" style="width: 280px">物料加工</td>
            </table>
            <thead>
              <tr>
                <td class="thead1td" @click="withs">关于我们</td>
               
                <td class="thead1td" @click="goproduct(0,'PE鄂式破碎机')">PE鄂式破碎机</td>
                <td class="thead1td" @click="solve">综合生产线</td>
                <td class="thead1td">花岗岩 &nbsp; &nbsp; 玄武岩</td>
              </tr>
              <tr>
                <td class="thead1td" @click="withs">生产基地</td>
                <td class="thead1td"  @click="goproduct(4,'H多缸液压圆锥破碎机')">H多缸液压圆锥破碎机 </td>
                <td class="thead1td" @click="solve">洗砂制砂生产线</td>
                <td class="thead1td">鹅卵石 &nbsp; &nbsp; 石灰石</td>
              </tr>
              <tr>
                <td class="thead1td" @click="withs">公司大事件</td>
                <td class="thead1td" @click="goproduct(8,'PF反击式破碎机')">PF反击式破碎机</td>
                <td class="thead1td" @click="solve">碎石生产线</td>
                <td class="thead1td">安山岩 &nbsp; &nbsp; 辉绿岩</td>
              </tr>
              <tr>
                <td class="thead1td" @click="withs">企业荣誉</td>
                <td class="thead1td"  @click="goproduct(9,'PFQ强力反击式破碎机')">PFQ强力反击式破碎机</td>
                <td class="thead1td"></td>
                <td class="thead1td">蛇纹岩 &nbsp; &nbsp; 铁矿石</td>
              </tr>
              <tr>
                <td class="thead1td"></td>
                <td class="thead1td"  @click="goproduct(3,'PSC弹簧圆锥破碎机')">PSC弹簧圆锥破碎机</td>
                <td class="thead1td"></td>
                <td class="thead1td">
                  钢渣 &nbsp;&nbsp; &nbsp; &nbsp; 建筑垃圾
                </td>
              </tr>
            </thead>
          </div>
          <div class="right">
            <span class="right-s">关注华南</span><br />
            <span class="right_s1">
              <img
                style="width: 100%; height: 100%"
                src="../assets/images/关注华南机械.png"
                alt=""
              />微信公众号
            </span>
            <span class="right_s2">
              <img
                style="width: 100%; height: 100%"
                src="../assets/images/关注华南机械 视频号.png"
                alt=""
              />微信视频号
            </span>
          </div>
        </div>
      </div>
    </div>
    <div style="color: #cccc; width: 100%;text-align:center;background-color:#000;line-height: 50px;">版权所有 华南机械制造有限公司  备案号：粤ICP备19000104号-1</div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  data() {
    return {
     
    };
  },
  mounted() {

  },
  methods:{
    // 点击关于我们相关
    withs(){
      // const index = 123
      // this.$eventBus.emit()
      router.push('/walk', () => { }, () => { })
    },
    // 点击底部产品目录
    goproduct(number , title){
      localStorage.setItem('productid',number)
      this.$router.push({
        name:'details',
        params:{
          id : number,
          crushText: title,
        }
      })
    },
    solve(){
      router.push('/case', () => { }, () => { })
    }
    
  }
};
</script>

<style>
/* 结尾 */
* {
  padding: 0;
  margin: 0;
}

.ending {
  position: relative;
  width: 100%;
  height: 256px;
  background: #cf000e;
  /* opacity: 0.8; */
  cursor: pointer;
}

.endingBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 100%;
}

.top {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: space-evenly;
}
.left {
  width: 75%;
}

.right {
  width: 25%;
  height: 100%;
}
.tab1td {
  width: 300px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  line-height: 80px;
  /* margin-left: 25px; */
  /* margin-top: 35px; */
}

.thead1td {
  width: 300px;
  height: 10px;
  /* border: 1px solid #fff; */
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 25px;
}
.right-s {
  width: 70%;
  height: 40px;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  line-height: 25px;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 20px;
  /* background-color: #30a890; */
}

.right_s1 {
  /* float: left; */
  display: inline-block;
  width: 40%;
  height: 40%;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 20px;
}

.right_s2 {
  display: inline-block;
  width: 40%;
  height: 40%;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 20px;
  margin-left: 5px;
}

.xt {
  position: absolute;
  top: 30%;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

@media all and (max-width: 820px) {
  .endingBox {
    width: 80%;
  }
  .top {
  }
  .left {
    display: none;
  }
  .right {
    /* background-color: yellow; */
    width: 100%;
  }
  .right-s {
    width: 100%;
    text-align: center;
  }
  .right_s1 {
    width: 130px;
    height: auto;
    float: left;
  }
  .right_s2 {
    width: 130px;
    height: auto;
    float: right;
  }
}

@media screen and (min-width: 1921px) {
  .right_s1 {
    height: 50%;
  }
  .right_s2 {
    height: 50%;
  }
}
</style>
