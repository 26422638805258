<template>
  <div>
    <!-- nav -->
    <Nav></Nav>
    <div class="case_header">
      <div class="case_banner">
        <img
          class="bannerimg"
          src="../assets/华南官网2.0素材/项目案例/矩形 3.png"
          alt=""
        />
      </div>
      <div class="case_header_title">项目案例</div>
    </div>
    <div class="case_page">
      <div class="case_page_title">
        <span>华南机械 > 项目案例 > {{ this.CaseData.name }}</span>
      </div>
    </div>
    <!-- middle -->
    <div class="middle">
      <div class="middleNumber1">
        <p class="Number1P1">{{ this.CaseData.name }}</p>
        <div class="Numberbox">
          <div class="Numberbox1">
            <img
              style="width: 100%; height: 100%"
              :src="this.CaseData.RelatedPictures[0].phatImg"
              alt=""
            />
          </div>
          <div class="Numberbox2">
            <p class="Numberbox2P">{{ this.CaseData.GenerationType }}</p>
            <p class="Numberbox2P">{{ this.CaseData.FinishedProduct }}</p>
            <p class="Numberbox2P">{{ this.CaseData.equipment }}</p>
            <p class="Numberbox2P">{{ this.CaseData.service }}</p>
            <div class="NumberBut">
              <div class="NumberButbox1">咨询项目详情</div>
              <div class="NumberButbox2" @click="hono">资讯热线：020-87066918</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 项目展示 -->
    <div class="ProjectPresentation">
      <div class="PresentationNav">{{ this.CaseData.name }}图片展示</div>
      <div class="PresentationBut">
        <div
          class="item"
          v-for="(item, index) in this.CaseData.RelatedPictures"
          :key="index"
        >
          <img :src="item.phatImg" style="width: 100%; height: 100%" alt="" />
        </div>
      </div>
    </div>

    <!-- 相关产品 -->
    <div class="RelatedProducts">
      <div class="RelatedProductsNav">相关产品</div>
      <div class="RelatedProductsBut">
        <div
          class="item"
          v-for="(item, index) in this.CaseData.RelatedProducts"
          @click="RelatedProducts(index)"
          :key="index"
        >
          <div class="itembox1">
            <img
              style="display: block; margin: auto"
              :src="item.productsImg"
              alt=""
              srcset=""
            />
          </div>
          <div class="itembox2">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <!-- 相关案例 -->
    <div class="RelatedProducts">
      <div class="RelatedProductsNav">相关案例</div>
      <div class="RelatedProductsBut">
        <div
          class="item"
          v-for="(item, index) in this.CaseData.RelevantCase"
          @click="RelevantCase(item.id)"
          :key="index"
        >
          <div class="itembox1">
            <img :src="item.CaseImg" style="width: 100%; height: 100%" alt="" />
          </div>
          <div class="itembox2">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <callme style="margin-top: 50px"></callme>
    <FTrecord></FTrecord>
  </div>
</template>

<script>
import Nav from "../components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import callme from "../components/callme.vue";
import router from "@/router";
// import axios from 'axios';
export default {
  components: {
    Nav: Nav,
    FTrecord: FTrecord,
    callme: callme,
  },
  data() {
    return {
      CaseData: [],
    };
  },
  watch: {
    "$route.params.CaseText": function (news, olds) {
      // console.log(this.$route.params.CaseText);
      this.CaseIdAPI();
    },
  },
  mounted() {
    this.CaseIdAPI();
  },
  methods: {
    CaseIdAPI() {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "./casedetalis.json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var res = JSON.parse(xhr.responseText);

          const CaseId = parseInt(localStorage.getItem("CaseId"));
          this.CaseData = res.data[0].crush[CaseId];
          console.log(this.CaseData.RelatedProducts);
        }
      };
      xhr.send();
    },
    RelevantCase(id) {
      localStorage.setItem("productid", id);
      let titleText = this.CaseData.RelevantCase[id].name;
      this.$router.push({
        name: "CaseDetails",
        params: {
          CaseId: id,
          CaseText: titleText,
        },
      });
      localStorage.setItem("CaseId", id);
    },
    RelatedProducts(index) {
      localStorage.setItem("productid", index);
      let crushText = this.CaseData.RelatedProducts[index].name;
      this.$router.push({
        name: "details",
        params: {
          id: index,
          crushText: crushText,
        },
      });
    },
    hono(){
      location.href = "tel:020-87066918"; // 拨打123456789
      console.log(1);
    }
  },
};
</script>

<style scoped>
/* 顶部 */
.case_header {
  width: 100%;
  height: 260px;
  position: relative;
  -webkit-animation: mov 5s infinite;
}
@-webkit-keyframes mov {
}

.bannerimg {
  width: 100%;
  height: 260px;
}

.case_header_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  letter-spacing: 2.4px;
  margin: auto;
}
@media all and (max-width: 821px) {
  .case_header {
    width: 100%;
    height: 130px;
  }
  .bannerimg {
    width: 100%;
    height: 130px;
  }
}

.case_page {
  width: 100%;
  height: 55px;
  background: #f4f4f4;
  -webkit-animation: mov 5s infinite;
}
@-webkit-keyframes mov {
}
.case_page_title {
  width: 60%;
  margin: 0 auto;
  line-height: 70px;
  font-size: 12px;
}
@media all and (max-width: 821px) {
  .case_page_title {
    width: 95%;
    line-height: 55px;
  }
}

/* middle */
.middle {
  width: 100vw;
  margin-top: 50px;
  padding-bottom: 50px;
  border-bottom: 8px solid #ccc;
  -webkit-animation: mov 5s infinite;
}
@-webkit-keyframes mov {
}
.middleNumber1 {
  width: 60%;
  height: 400px;
  margin: auto;
}

.Number1P1 {
  display: block;
  width: 100%;
  height: 24px;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
  line-height: 24px;
  padding-bottom: 50px;
}

.Numberbox {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-between;
}

.Numberbox1 {
  width: 45%;
  height: 100%;
}

.Numberbox2 {
  width: 50%;
  height: 100%;
}

.Numberbox2P {
  display: block;
  width: 120%;
  font-size: 14px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Regular;
  font-weight: 400;
  text-align: left;
  color: #221815;
  line-height: 30px;
}

.NumberBut {
  width: 100%;
  height: 40px;
  display: flex;
  margin-top: 80px;
  justify-content: space-around;
}

.NumberButbox1,
.NumberButbox2 {
  width: 45%;
  height: 40px;
  background: #cf000e;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
  color: aliceblue;
  font-weight: 400;
  cursor: pointer;
}
@media all and (max-width: 821px) {
  .middle {
    margin-top: 15px;
    /* padding-bottom: 0px; */
  }
  .middleNumber1 {
    width: 95%;
    height: 558px;
  }
  .Number1P1 {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
  }
  .Numberbox {
    flex-wrap: wrap;
  }
  .Numberbox1 {
    width: 100%;
    height: 200px;
  }
  .Numberbox2 {
    width: 100%;
    padding-top: 15px;
  }
  .NumberButbox2 {
    word-wrap: break-word;
  }
}

/* 项目展示 */
.ProjectPresentation {
  width: 60vw;
  height: 500px;
  margin: auto;
  margin-top: 50px;
  border-bottom: 1px solid #ccc;
  -webkit-animation: mov 5s infinite;
}
@-webkit-keyframes mov {
}
.PresentationNav {
  width: 100%;
  height: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  text-align: center;
  padding-bottom: 50px;
}
.PresentationBut {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.PresentationBut .item {
  width: 32%;
  height: 45%;
  background-color: palevioletred;
}
@media all and (max-width: 821px) {
  .ProjectPresentation {
    width: 95%;
    height: 500px;
  }
  .PresentationNav {
    font-size: 14px;
    font-weight: 700;
  }
  .PresentationBut .item {
    width: 49%;
    height: 125px;
  }
}

/* 相关产品 */
.RelatedProducts {
  width: 60vw;
  height: 300px;
  margin: auto;
  margin-top: 50px;
  border-bottom: 1px solid #ccc;
  -webkit-animation: mov 5s infinite;
}
@-webkit-keyframes mov {
}
.RelatedProductsNav {
  width: 100%;
  height: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  text-align: center;
  padding-bottom: 50px;
}
.RelatedProductsBut {
  width: 100%;
  height: 180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.RelatedProductsBut .item {
  width: 24%;
  height: 100%;
}
.itembox1 {
  width: 100%;
  height: 140px;
  padding-bottom: 15px;
}
.itembox2 {
  width: 100%;
  height: 40px;
  background-color: #ccc;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}
@media all and (max-width: 821px) {
  .RelatedProducts {
    width: 95%;
    height: 435px;
    /* margin-top: 35px; */
    /* background-color:red; */
    padding-bottom:100px;
  }
  .RelatedProductsNav {
    font-size: 14px;
    font-weight: 700;
  }
  .RelatedProductsBut {
    height: 170px;
  }
  .RelatedProductsBut .item {
    width: 49%;
    margin-top:35px;
  }
  .itembox1 {
    height: 135px;
  }
  .itembox2{
    font-size:10px;
  }

}
</style>
