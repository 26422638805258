<template>
  <div :style="{
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    border: '1px solid #ebedf0',
    borderRadius: '2px',
    textAlign: 'center',
    background: '#fafafa',
  }">
    <div id="container" style="width: 100%; height: 100%; overflow: hidden">
      <div class="setBtn" @click="showDrawer">
        <div>模</div>
        <div>型</div>
        <div>设</div>
        <div>置</div>
      </div>

      <div class="model_name">
        {{ readyStateNmae }}
      </div>
    </div>
    <a-drawer title="3D 模型设置" placement="left" :visible="visible" :mask="false" :get-container="false"
      :wrap-style="{ position: 'absolute' }" @close="onClose">
      <div class="form_box">
        <div class="item_btn">
          自动旋转
          <a-switch default-checked @change="revolvebtn" />
        </div>
        <div class="item_btn">
          显示网格
          <a-switch @change="showGridding" />
        </div>
        <div class="item_btn">
          显示尺寸
          <a-switch default-checked @change="showSizi" />
        </div>
        <div class="item_Split">
          模型拆分
          <a-slider v-model="inputValue1" :min="0" :max="100" @change="onSplit" />
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { nextTick } from 'vue';
// import "ant-design-vue/dist/antd.css";

export default {
  props: ["readyState"],
  components: {},
  data() {
    return {
      param: {
        appkey: "PRDVAhxgdSUe",
        appsecret: "wDWbJknUps1gRGqTKE5Bar8Z4zV2lxYI",
      },
      viewer: null,
      model: null,
      defaultSet: true,
      inputValue1: 0,
      visible: false,
      readyStateNmae: "",
      productSizi: [],
    };
  },
  mounted() {
    // this.init();
    // setTimeout(() => {
    //   // 延时后执行的代码
    //   //   this.viewer.sceneManager.setBackground("#afafaf");  //修改背景颜色
    //   this.viewer.sceneManager.setBackground(
    //     "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E4%BA%A7%E5%93%81%E8%AF%A6%E6%83%85/details_bg.png"
    //   );
    // }, 8000); // 2秒延时
  },
  watch: {
    // defaultSet: {
    //   deep: true,
    //   immediate: true,
    //   handler(a, b) {
    //     this.$nextTick(() => {
    //       if (this.viewer) {
    //         this.viewer.controls.startAutoRotate(1);
    //       }
    //     });
    //   },
    // },
    readyState(newVal, oldVal) {
      console.log(newVal, oldVal, 999999);

      if (newVal) {
        // 在接收到数据后执行后续代码
        console.log(newVal, oldVal);
        this.init();
        setTimeout(() => {
          // 延时后执行的代码
          //   this.viewer.sceneManager.setBackground("#afafaf");  //修改背景颜色
          this.viewer.sceneManager.setBackground(
            "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E4%BA%A7%E5%93%81%E8%AF%A6%E6%83%85/details_bg.png"
          );
        }, 8000); // 2秒延时
      }
    },
  },

  methods: {
    async init() {
      this.viewer = new AMRT.Viewer(
        "container",
        /*容器dom或者dom对应的id*/ this.param
      );
      //通过老子云平台 ID 加载一个模型
      console.log(this.readyState, 333333333333333);
      const searchParams = new URLSearchParams(
        new URL(this.readyState.model).search
      );
      this.readyStateNmae = this.readyState.name.replace(/[A-Z]/g, "");
      const id = searchParams.get("id");
      this.productSizi = this.readyState.productSizi;
      console.log(this.productSizi, 22222222222);
      this.viewer.controls.startAutoRotate(1);
      this.model = this.viewer.loadModel(id, {
        focus: true,
        onLoad: (m) => {
          m.showDimension(
            this.productSizi[0] + "m",
            this.productSizi[1] + "m",
            this.productSizi[2] + "m"
          );
        },
      });
    },



    revolvebtn(flag) {
      //旋转按钮
      flag
        ? this.viewer.controls.startAutoRotate(1)
        : this.viewer.controls.stopAutoRotate();
    },

    showSizi(flag) {
      //显示尺寸
      console.log(flag);
      flag
        ? this.model.showDimension(
          this.productSizi[0] + "m",
          this.productSizi[1] + "m",
          this.productSizi[2] + "m"
        )
        : this.model.hideDimension();
    },
    showGridding(flag) {
      //网格按钮
      this.model.applyWireFrame(flag);
    },

    onSplit(value) {
      console.log("change: ", value / 100);
      this.model.setExploding(value / 100);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
  },
};
</script>

<style>
.model-box {
  height: "700px";
  overflow: "hidden";
  position: "relative";
  border: "1px solid #ebedf0";
  background: "#fafafa";
}

.form_box {
  height: 260px;
}

.item_btn {
  height: 20%;
  display: flex;
  justify-content: space-between;
}

.item_Split {
  text-align-last: left;
  height: 20%;
}

.ant-drawer-wrapper-body {
  background-color: #6b6b6b;
  color: #fff;
}

.ant-drawer-header {
  background-color: #6b6b6b;
  color: #fff !important;
}

.ant-drawer-title {
  color: #fff;
}

.ant-drawer-close {
  color: #fff;
}

.model_name {
  position: absolute;
  bottom: 12%;
  right: 8%;
  font-size: 22px;
  font-weight: 700;
  color: #6b6b6b;
}

.setBtn {
  position: absolute;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  padding-top: 8px;
  padding: 8px 8px;
  padding-bottom: 8px;
  top: 40%;
  font-size: 16px;
  color: #fff;
  /* width: 26px; */
  background-color: #6b6b6b;
}
</style>
