<template>
  <div>
    <Nav></Nav>
    <div :class="walkimg">
      <div class="navbox">
        <div class="boxtext">
          <p class="boxtextP1">技术支持</p>
          <p class="boxtextP2">我们将及时为您提供专业的咨询服务</p>
        </div>
      </div>
    </div>

    <div class="walknav">
      <div class="walknavbox">华南机械 > 技术支持</div>
    </div>

    <div class="concent">
      <div class="concentitem" v-for="(item, index) in technical" :key="index">
        <div class="concentnav">
          <img style="width: 100%; height: 100%" :src="require(`../assets/华南官网2.0素材/技术支持/${item.img}`)" alt="" />
        </div>
        <div class="concentbut">
          <p class="concentP1">{{ item.title }}</p>
          <p class="concentP2">{{ item.text }}</p>
        </div>
      </div>
    </div>

    <div class="BusinessDomain" v-if="BusinessDomainFlag">
      <div class="nav">
        <p>业务领域</p>
      </div>
      <div class="BusinessDomainbut">
        <div class="box" v-for="(item, index) in BusinessDomain" :key="index">
          <div class="box_title">
            <img class="title_img" :src="
              require(`../assets/images/nhjd/技术支持/业务领域/${item.img}`)
            " alt="" />
            <span class="title_text">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <callme data-wow-delay="0.2s" class="wow animate__fadeInUp callme"></callme>
    <FTrecord data-wow-delay="0.2s" class="wow animate__fadeInUp "></FTrecord>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import NewsInquiry from "../components/NewsInquiry.vue";
import callme from "../components/callme.vue";
import "wowjs/css/libs/animate.css";
import { WOW } from "wowjs";
export default {
  components: {
    Nav: Nav,

    FTrecord: FTrecord,
    // 新闻资讯
    NewsInquiry: NewsInquiry,
    callme: callme,
  },
  data() {
    return {
      technical: [
        {
          title: "售前服务",
          text: "我们会及时时间与客户互动，了解客户的具体需求情况，物料成分，预定工作现场等相关信息。",
          img: "1.png",
        },
        {
          title: "售中服务",
          text: "公司可派工程技术人员到客户现场为其规划场地、设计合理流程和方案，确保其利润较大化。",
          img: "2.png",
        },
        {
          title: "售后服务",
          text: "指派专业售后人员到达现场指导客户安装调试，并安排技术人员协助客户现场，直到用户满意。",
          img: "3.png",
        },
      ],
      // 业务领域数据
      BusinessDomain: [
        { img: "技术支持.png", title: "技术支持" },
        { img: "定期回访.png", title: "定期回访" },
        { img: "配件供应.png", title: "配件供应" },
        { img: "维护保养.png", title: "维护保养" },
        { img: "设备供应.png", title: "设备供应" },
        { img: "安装调试.png", title: "安装调试" },
        { img: "方案设计.png", title: "方案设计" },
        { img: "地形勘测.png", title: "地形勘测" },
        { img: "场地规划.png", title: "场地规划" },
        { img: "升级改造.png", title: "升级改造" },
        { img: "生产培训.png", title: "生产培训" },
        { img: "投资合作.png", title: "投资合作" },
      ],
      walkimg: "",
      BusinessDomainFlag: "true",
      FTrecord: "true",
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateClassName); // 监听窗口大小变化
    this.updateClassName(); // 初始化时更新类名

    this.$nextTick(() => {
      new WOW().init()
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateClassName); // 移除窗口大小变化的监听器
  },
  methods: {
    updateClassName() {
      this.walkimg = window.innerWidth > 1200 ? "walkimg" : "walkimg_min";

      this.BusinessDomainFlag = window.innerWidth > 1200 ? true : false;
    },
  },
};
</script>

<style scoped>
.walkimg {
  width: 100vw;
  height: 260px;
  background-image: url(../assets/华南官网2.0素材/技术支持/矩形\ 3.png);
  background-color: red;
}

.navbox {
  width: 60%;
  height: 100%;

  margin: 0 auto;
  position: relative;
}

.boxtext {
  width: 50%;
  height: 30%;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.boxtextP1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  /* line-height: 77px; */
  letter-spacing: 2.4px;
}

.boxtextP2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 50px;
}

.walkimg_min {
  width: 100vw;
  height: 130px;
  background-image: url(../assets/华南官网2.0素材/技术支持/矩形\ 3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.walkimg_min .box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.walkimg_min .box .boxtext {
  width: 100%;
  height: 30%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.walkimg_min .boxtext .P1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  /* line-height: 77px; */
  letter-spacing: 2.4px;
}

.walkimg_min .boxtext .P2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 50px;
}

.walknav {
  width: 100vw;
  height: 50px;
  background-color: #f4f4f4;
}

.walknavbox {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 50px;
  cursor: pointer;
}

.concent {
  width: 60vw;
  height: 321px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
}

.concentitem {
  width: 31%;
  height: 321px;
  /* background-color: ; */

  transition: width 1s;
}

.concentnav {
  width: 100%;
  height: 239px;
}

.concentbut {
  width: 100%;
  height: 82px;
}

.concentP1 {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 35px;
}

.concentP2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 20px;
}

.concentitem:hover {
  width: 33%;
}

.BusinessDomain {
  width: 60vw;
  height: 400px;
  margin: 50px auto;
}

.nav {
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: center;
  color: #000000;
  line-height: 50px;
}

.BusinessDomainbut {
  width: 100%;
  height: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.box {
  width: 24%;
  height: 31%;
  background: #e9e9e9;
  /* background: -moz-linear-gradient(#cf000d,#605656); */
  border-radius: 0;
  transition: border-radius 1s, background 1s;
}

.box_title {
  width: 150px;
  height: 41px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.title_img {
  /* width: 30%;
                    height: 50%; */
  float: left;
}

.title_text {
  float: right;
  line-height: 41px;
  text-align: right;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
}

.box:hover {
  background: #fb2c3a;
  border-radius: 25px;
}

@media screen and (max-width:821px) {
  .concent {
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* 允许子元素换行 */
    margin-bottom: 700px;
  }

  .concentitem {
    width: 100%;
    height: 100%;
  }

  .concentP1 {
    text-align: center
  }

  .callme {
    padding-bottom: 75px;
  }
}</style>
