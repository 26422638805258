<template>
  <div>
    <Nav></Nav>
    <div class="img">
      <div class="imgbox">
        <p class="P1">新闻中心</p>
      </div>
    </div>

    <!-- 公司新闻 -->
    <div class="daohan">
      <div class="box">公司新闻 > 新闻中心</div>
    </div>
    <!-- <div class="gsnews">
            <div class="gsnewmav">
                <div style="font-size: 14px;">公司新闻</div>
                <div style="font-size: 14px;color: red;"  @click="gonewsDetails">查看更多>></div>
            </div>
            <div class="gsnewsBox">
                <div class="gsnewsBox1"><img src="../assets/华南官网2.0素材/新闻中心/矩形 758.png" style="width: 100%;height: 100%;"
                        alt=""></div>
                <div class="gsnewsBox2">
                    <p class="Box2P1" style="line-height: 25px;">华南机械4000T/H砂石骨料项目建设现场</p>
                    <p class="Box2P2" style="line-height: 35px;">日期：2022-12-28 09:36:13</p>
                    <p class="Box2P3">项目建设无小事，小到基础建设大到投产运营，都是影响总体产能及稳定生产的决定性因素。
                        那些隐藏在视觉之下的基础、焊接、安装角度等细节工艺，疏忽掉其中的一个细微的步骤，都会对生产线的质量大打折扣； 从源头拒绝华而不实的“面子工程”，透过“魔鬼细节”看真相，打造内外兼修的大型生产线样板！
                    </p>
                </div>
            </div>
            <div class="gsnewsul">
                <div class="item"  @click="gonewsDetails"><span>· 华南机械4000T/H砂石骨料项目建设现场</span><span class="span"
                        style="float: right;">2022-12-28</span>
                </div>
                <div class="item">· 让传统破碎生产线跟上"环保、智能"新步伐<span class="span" style="float: right;">2022-12-19</span></div>
                <div class="item">· 解密║机制砂生产线的升级改造方案<span class="span" style="float: right;">2022-12-02</span></div>
                <div class="item">· 不为困难找借口，只为实干想办法<span class="span" style="float: right;">2022-11 -24</span></div>

            </div>

            <div class="gszxun">
                <div style="font-size: 14px;">行业资讯</div>
                <div style="font-size: 14px; color: red;" @click="gocase">了解更多>></div>
            </div>
            <div class="gsnewsul" style="border-bottom: 1px solid #cccc;">
                <div class="item"><span>· 华南机械4000T/H砂石骨料项目建设现场</span><span class="span"
                        style="float: right;">2022-12-28</span>
                </div>
                <div class="item">· 让传统破碎生产线跟上"环保、智能"新步伐<span class="span" style="float: right;">2022-12-28</span></div>
                <div class="item">· 解密║机制砂生产线的升级改造方案<span class="span" style="float: right;">2022-12-02</span></div>
                <div class="item">· 不为困难找借口，只为实干想办法<span class="span" style="float: right;">2022-11-24</span></div>
            </div>
        </div> -->

    <!-- <callme class="callme"></callme> -->

    <div class="news_list">
      <div
        class="news_item"
        v-for="(item, index) in limitedNewsList"
        :key="index"
        @click="
          $router.push({
            name: 'newDetails',
            params: { content: item.content, time: item.time, day: item.day },
          })
        "
      >
        <div class="news_box">
          <img :src="item.img" alt="" />
          <div class="news_text">
            <div>{{ item.title }}</div>
            <div>
              {{ item.text }}
              <div :style="{ 'margin-top': '2px' }">
                {{ item.textTwo }}
              </div>
            </div>
          </div>
        </div>

        <div class="news_time">
          <div class="time">
            <div>{{ item.day }}</div>
            <div>{{ item.time }}</div>
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <div class="paging">
        <a-pagination
          v-model="current"
          :page-size-options="pageSizeOptions"
          :total="newsList.length"
          show-size-changer
          :page-size="pageSize"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
            <span v-if="props.value === '50'">全部</span>
          </template>
        </a-pagination>
      </div>
    </div>

    <FTrecord></FTrecord>
  </div>
</template>

<script>
import Nav from "../components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import router from "@/router";
import NewsInquiry from "../components/NewsInquiry.vue";
import callme from "../components/callme.vue";
export default {
  components: {
    Nav: Nav,
    FTrecord: FTrecord,

    // 新闻资讯
    NewsInquiry: NewsInquiry,
    callme: callme,
  },
  data() {
    return {
      FTrecord: "true",
      FTrecord_min: "true",
      img: "",
      pageSizeOptions: ["5", "10", "15", "20", "25"],
      current: 1,
      pageSize: 5,
      total: 50,
      newsList: [
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/%E9%A6%96%E5%9B%BE.png",
          title: "干货分享丨振动筛的运行轨迹，你知道多少？",
          text: "如果按照物料的运行轨迹来划分，那么振动筛可以分为哪几种类型?",
          day: "26",
          time: "2024-10",
          content: {
            newsDetalisTitle: "干货分享丨振动筛的运行轨迹，你知道多少？",
            newsDetalis: [
              {
                type: "textName",
                content:
                  "如果按照物料的运行轨迹来划分，那么振动筛主要分为3种类型。",
              },
              {
                type: "textName",
                content: "【一、圆振动】",
              },
              {
                type: "text",
                content:
                  "圆振动筛，也称斜筛，两个偏心块同向旋转，呈圆振动轨迹。17-23°倾斜安装，振幅一般为6-10mm，适合大颗粒物料筛分，通常用于检查筛分。",
              },
              {
                type: "textName",
                content: "【华南机械设备推荐】",
              },
              {
                type: "Title_img",
                title: "1.YKJ振动筛系列",
                content:
                  "YKJ振动筛系列，振幅8-10mm，是专门为采石场筛分石料设计的，也可供选煤、选矿、建材、电力及化工部门等做产品分级用。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/1.jpg",
              },
              {
                type: "Title_img",
                title: "（1）3D效果图",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/2.gif",
              },
              {
                type: "textName",
                content: "（2）特点",
              },
              {
                type: "text",
                content: "1、振动幅度可以调节。",
              },
              {
                type: "text",
                content: "2、结构合理，坚固耐用。",
              },
              {
                type: "text",
                content: "3、处理能力大。",
              },
              {
                type: "text",
                content: "4、结构好，寿命长，用料足。",
              },
              {
                type: "text",
                content: "5、筛分均匀。",
              },
              {
                type: "text",
                content:
                  "6、筒式激振器直接安装在筛箱上，不必采用结构笨重的工字横梁，整个筛箱的高度较小，质心降低，质量减轻，增加了座式筛机工作的稳定性。",
              },
              {
                type: "text",
                content:
                  "7、激振力相当于沿整个筛宽的均布载荷，安装精度较易保证，其误差对筛箱各点振幅影响较小。",
              },
              {
                type: "Title_img",
                title: "（3）技术参数",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/3.jpg",
              },

              {
                type: "textName",
                content: "【二、直线振动】",
              },
              {
                type: "text",
                content:
                  "两个偏心块反向运行，形成上下的直线跳动，轨迹呈直线型。振幅6-10mm，多适用于脱水、精细的物料筛分环节。",
              },
              {
                type: "textName",
                content: "【华南机械设备推荐】",
              },
              {
                type: "Title_img",
                title: "1.HNZ脱水筛系列",
                content:
                  "HNZ脱水筛系列，即双轴双电机脱水筛，采用两个偏心轴相对转动实现物料在筛网上的直线运动，通过电机直接对偏心轴进行驱动，结构紧凑、脱水效率高；具有刚度和强度大、脱水率高、运行稳定可靠的特点，可广泛应用于尾矿处理、洗煤、水洗石英砂、泥浆脱水等脱介、脱水、脱泥处理。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/4.png",
              },
              {
                type: "Title_img",
                title: "（1）3D效果图",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/5.gif",
              },
              {
                type: "textName",
                content: "（2）特点",
              },
              {
                type: "text",
                content: "1、结构紧凑，整机刚度与各部强度大。",
              },
              {
                type: "text",
                content: "2、相对体积小，单位面积处理能力大。",
              },
              {
                type: "text",
                content:
                  "3、相对耗能少，脱水率高安装与调试方便，运行可靠，操作维修简便。。",
              },
              {
                type: "text",
                content: "4、筛面结构与类型模块化设计，更换方便。",
              },

              {
                type: "Title_img",
                title: "（3）技术参数",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/6.png",
              },
              {
                type: "Title_img",
                title: "2.箱式激振器脱水筛",
                content:
                  "箱式激振器脱水筛主要由箱体、齿轮、主动轴、偏心块等组成。当电动机通过传动轴拖动箱式激振器，主动轴经齿轮驱动冲动轴，使两轴等速反向旋转，带动偏心块产生激振力，通过箱体传递给振动机械。箱式激振器的激振力为直线激振力，故也称为直线激振器。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/7.png",
              },
              {
                type: "Title_img",
                title: "（1）3D效果图",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/8.gif",
              },
              {
                type: "textName",
                content: "（2）特点",
              },
              {
                type: "text",
                content: "1、噪音小、维修方便。",
              },
              {
                type: "text",
                content: "2、结构合理、坚固耐用。",
              },
              {
                type: "text",
                content: "3、脱水效果好，安全可靠。",
              },
              {
                type: "text",
                content: "4、筛分处理能力大，筛分效率高。",
              },
              {
                type: "text",
                content: "5、重量轻，拆装方便，便于维护。",
              },

              {
                type: "Title_img",
                title: "（3）技术参数",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/9.png",
              },
              {
                type: "textName",
                content: "【三、椭圆振动】",
              },
              {
                type: "text",
                content:
                  "椭圆振动筛，也叫水平筛。高性能筛分的一款设备，兼顾直线筛和圆振筛的优势。适用于各类散装物料成品筛分环节。",
              },
              {
                type: "textName",
                content: "【华南机械设备推荐】",
              },
              {
                type: "Title_img",
                title: "1.HEV水平椭圆振动筛",
                content:
                  "HEV水平椭圆振动筛，振幅12-16mm，采用筒体式激振器及调整配重块调节振幅，是专门为采石场筛分石料设计的，可供矿山、选煤、选矿、建材、电力及化工部门等作产品分级用。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/10.png",
              },
              {
                type: "Title_img",
                title: "（1）3D效果图",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/11.gif",
              },
              {
                type: "textName",
                content: "（2）特点",
              },
              {
                type: "text",
                content: "1、处理能力大，筛分效率高。",
              },
              {
                type: "text",
                content: "2、安装空间小，用地面积小，投资及安装成本低。",
              },
              {
                type: "text",
                content: "3、筛分物料运行轨迹为椭圆抛物线，透孔率更高。",
              },
              {
                type: "text",
                content:
                  "4、双西门子电机，高精密度、误差更小、转速更同步和稳定、运行可靠。",
              },
              {
                type: "text",
                content:
                  "5、激振器在筛体上方，不阻挡落料，增加有效筛分面积，减少损，易拆卸更换维修便捷。",
              },
              {
                type: "text",
                content:
                  "6、筛体侧板采用特种钢结构、全身无焊点、采用高强度铆钉冷铆装配、筛框一体。",
              },
              {
                type: "text",
                content:
                  "7、配置专利自同步装置，多甩锤偏心同步运转，振频振幅稳定，运行可靠，大大延长设备使用寿命。",
              },
              {
                type: "text",
                content:
                  "8、高振幅(12-16mm，比普通筛高50%以上)，高振频(900-1000r/min，比普通筛高20%以上)，筛分效果好。",
              },

              {
                type: "Title_img",
                title: "（3）技术参数",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/12.jpg",
              },
              {
                type: "Title_img",
                title: "3.HED超级脱水筛系列",
                content:
                  "通过双电机反向运动传递激振力，激振力改变砂石泥浆里的水张力，浆水透过筛网成为筛下物，而微细物料被筛网阻隔形成滤层，滤层再受振动力影响，向前做直线运动从每层出料口排出。设备主要用于各种矿山制砂洗砂中细颗粒物料进行干湿式分级、脱水和脱泥。高振频、高振力、偏心块+偏心轴作为激振力,筛机结构简单，维修方便快捷,对比同等体型的同类产品产量更大、产出品质更优异。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/13.png",
              },
              {
                type: "Title_img",
                title: "（1）3D效果图",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/14.gif",
              },
              {
                type: "textName",
                content: "（2）特点",
              },
              {
                type: "text",
                content: "1、结构紧凑，整机刚度与各部强度大。",
              },
              {
                type: "text",
                content:
                  "2、相对体积小，单位面积处理能力大，便于系统工艺布置。",
              },
              {
                type: "text",
                content:
                  "3、相对耗能少，脱水率高安装与调试方便，运行可靠，操作维修便捷。",
              },
              {
                type: "text",
                content: "4、筛网结构与类型模块化，更换方便。",
              },
              {
                type: "text",
                content: "5、与同等体型的其他同类产品相比产量大。",
              },
              {
                type: "text",
                content:
                  "6、洗砂回收一体机，针对浆状物料脱水，脱介，脱泥开发的一种分和细砂回收设备。",
              },
              {
                type: "text",
                content:
                  "7、简式激振器直接安装在筛箱上，不必采用结构笨重的工字横梁，整个箱的高度较小，质心降低，质量减轻，增加了座式筛机工作的稳定性。",
              },
              {
                type: "text",
                content:
                  "8、激振力相当于沿整个筛宽的均布载荷，安装精度较易保证，其误差对筛箱各点振幅影响较小。",
              },

              {
                type: "Title_img",
                title: "（3）技术参数",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/726ff30cf5bda197dcc4fa5a747204d.jpg",
              },
              {
                type: "Title_img",
                title: "4.60120全球最大振动筛",
                content:
                  "运行轨迹为椭圆抛物线，可用于粗、中、细不同粒径的矿物筛分、脱介、脱泥、脱水、脱粉。有效筛分内空6m*12m，全球最大、振幅高达14-16mm、振频超过900r/min。变频启动控制，避免电机过载，提高设备安全性；全生命监测控制，拥有可检测电流、温度、转速、振幅、噪音、设备运行轨迹等数据。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/15.jpg",
              },
              {
                type: "Title_img",
                title: "（1）3D效果图",
                width: 800,
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/16.gif",
              },
              {
                type: "text",
                content:
                  "在实际生产工程中，原料、筛网、设备选型，都是影响砂石筛分效率的重要因素；为了能够实现产能最大化，最终选用哪一款振动筛，需要根据不同的原料、成品、生产工艺进行匹配。华南机械作为砂石骨料生产系统方案解决专家，受聘为多家大型矿企专业技术顾问，具有较丰富的方案设计及设备选型经验，能够高效解决生产线建设及生产过程中的各种难题，为客户创造效益。",
              },

              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/17.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.26%E6%8C%AF%E5%8A%A8%E7%AD%9B/18.png",
              },
            ],
          },
        },
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/%E9%A6%96%E5%9B%BE.jpg",
          title: "港口升级新利器，14520港口装船机现场安装完成",
          text: "华南机械港口设备再展实力，全新装船机安装完成！",
          day: "22",
          time: "2024-10",
          content: {
            newsDetalisTitle: "港口升级新利器，14520港口装船机现场安装完成",
            newsDetalis: [
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/1.jpg",
              },
              {
                type: "text",
                content:
                  "华南机械港口设备再展实力，全新装船机安装完成！14520港口装船机，用于港口码头、各大宗散货装船时使用的大型散料机械，日前，经过数日的紧张施工和持续奋战，顺利完成安装。",
              },
              {
                type: "video",
                videoUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/%E8%A7%86%E9%A2%91.mp4",
              },
              {
                type: "text",
                content:
                  "安装前期，公司相关部门经过多次技术研讨，制定出科学合理的14520港口装船机发运及吊装方案。确保施工安全高效，技术切实可行，为设备顺利安装奠定基础。",
              },
              {
                type: "text",
                content:
                  "从设计到生产，华南人对每一个环节进行严格把关，确保装船机的质量和性能达到最优；从运输到安装，日夜兼程、细致严谨，每一步都凝聚着团队的专业与用心。",
              },

              {
                type: "textName",
                content: "【一、设备介绍】",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/5.jpg",
              },
              {
                type: "text",
                content:
                  "目前，港口码头大宗散货装船主要靠机械起重式抓斗，效率低且占用地轨起重空间。这台由华南机械全自主研发的中国首台14520港口装船机，填补了散货装船自动化智能化的空白，效率是传统抓斗三倍以上，是灵活移动、伸缩、升降等多功能一体机。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/7.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/8.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/9.jpg",
              },
              {
                type: "text",
                content:
                  "不作业时可移到设备库，不占用集装箱地轨起重机作业空间。14520 港口装船机以其高效的装卸能力，为港口物流提供了更为便捷、安全的服务，进一步推动了行业的智能化转型。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/11.jpg",
              },
              {
                type: "text",
                content:
                  "在全球化的今天，高效的物流对于国家的经济发展至关重要。14520港口装船机拥有高效的装载能力，能够在短时间内完成大量货物的装船作业，大大提高港口的运营效率。",
              },

              {
                type: "textName",
                content: "【二、售后服务】",
              },
              {
                type: "text",
                content:
                  "华南机械设置24小时售后响应服务、设备运转效率跟踪及定期回访机制、齐全的配件及充足库存，专业的售后服务团队随时为客户排忧解难，确保装船机始终保持最佳状态。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/12.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/13.png",
              },

              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/14.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/15.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/16.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.22%E8%A3%85%E8%88%B9%E6%9C%BA%E5%AE%89%E8%A3%85%E5%AE%8C%E6%88%90/17.png",
              },
            ],
          },
        },
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/%E9%A6%96%E5%9B%BE.jpg",
          title: "行业标杆大型矿场生产线试产进行中",
          text: "由华南机械团队总规划、设备总供应、安装工程总承包的肇庆大型矿场生产线EPC项目现已进入带料试产运行阶段。",
          day: "17",
          time: "2024-10",
          content: {
            newsDetalisTitle: "行业标杆大型矿场生产线试产进行中",
            newsDetalis: [
              {
                type: "video",
                videoUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/%E5%A4%A7%E5%9E%8B%E7%9F%BF%E5%9C%BA%E7%94%9F%E4%BA%A7%E7%BA%BF%E6%88%90%E5%8A%9F%E8%AF%95%E4%BA%A7.mp4",
              },
              {
                type: "text",
                content:
                  "由华南机械团队总规划、设备总供应、安装工程总承包的肇庆大型矿场生产线EPC项目现已进入带料试产运行阶段。项目生产线涵盖的破碎、筛分、制砂、洗砂、长距离输送、污水处理等各环节已进入有条不紊运行试产中。",
              },

              {
                type: "textName",
                content: "【一、精心准备，周密部署】",
              },
              {
                type: "text",
                content:
                  "为确保生产线顺利投产，华南机械提前进行周密部署和精心准备。从设备调试到人员培训，从安全检查到应急预案制定，每一个环节都做到精益求精。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/3.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/2.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/1.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/4.jpg",
              },
              {
                type: "text",
                content:
                  "项目负责团队提前规划，多次组织相关技术人员、现场施工人员、规划设计师等召开专题会议，讨论、编写、修改投料试产方案，积极协调各方力量，通过持续改进和不断优化调整，确保了设备稳定运行和生产线工艺流程的顺畅衔接，为项目顺利推进打下坚实基础。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/5.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/6.jpg",
              },
              {
                type: "textName",
                content: "【二、技术过硬，成功试产】",
              },
              {
                type: "text",
                content:
                  "华南机械团队凭借专业技术和丰富经验，在该项目中展现出了极高的技术能力和卓越的执行力，高效快速解决设备的安装和调试中出现的各项问题，最大限度地保证了项目进度的推进。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/7.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/8.jpg",
              },

              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/9.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/10.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/11.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/12.jpg",
              },
              {
                type: "text",
                content:
                  "华南机械团队秉持着质量过硬、技术精湛、服务一流的核心价值理念，严格遵循了相关技术规范，为该产线的设计搭建保驾护航。",
              },

              {
                type: "textName",
                content: "【三、项目介绍，行业标杆】",
              },

              {
                type: "text",
                content:
                  "作为全国顶尖智能绿色砂石骨料矿山标杆，肇庆大型矿场生产线EPC项目，由华南机械首创广东砂石行业内首条水平拐弯输送带（带宽1600双通道），5.2公里景观式高效环保输送长廊，既美观又环保。",
              },
              {
                type: "text",
                content:
                  "“三段一闭路”湿法生产工艺，配备洗砂设备、污水处理系统全流程抑水防尘达到无尘输送的效果，保障安全施工、绿色生产。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/13.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/14.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/15.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.17%E7%94%9F%E4%BA%A7%E7%BA%BF%E8%AF%95%E4%BA%A7/16.png",
              },
            ],
          },
        },
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/1.jpg",
          title: "动态｜华南机械国庆不打烊，巨无霸设备奔赴项目现场",
          text: "国庆的喜悦尚未消散，奋斗的脚步已然前行，巨无霸设备——14520港口装船机顺利发往项目现场。",
          day: "06",
          time: "2024-10",
          content: {
            newsDetalisTitle:
              "动态｜华南机械国庆不打烊，巨无霸设备奔赴项目现场",
            newsDetalis: [
              {
                type: "text",
                content:
                  "金秋送爽，硕果累累。10月，是收获满满的季节，国庆的喜悦尚未消散，奋斗的脚步已然前行，华南人昼夜兼程、拼搏不已，将这个巨无霸设备——14520港口装船机顺利发往项目现场。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/1.jpg",
              },
              {
                type: "textName",
                content: "【一、震撼出动，直击现场】",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/2.jpg",
              },

              {
                type: "text",
                content:
                  "在装船机发货的现场，一切都在有条不紊地进行中。起重机的轰鸣声、工人的呼喊声交织在一起，奏响了一曲奋斗的乐章，从晨曦微露到夕阳西下，经过整整十个小时的忙碌准备，装船机才缓缓驶出厂区大门。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/3.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/4.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/5.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/6.jpg",
              },
              {
                type: "text",
                content:
                  "14520港口装船机由华南机械制造有限公司生产制造，克服了结构复杂、精度要求高等一系列技术难题，背后凝聚了无数华南人的心血，它不仅是一件即将奔赴新征程的设备，更是我们辛勤付出的见证，每一次拧紧螺丝，每一次调整设备，都是我们对工作的热爱与负责。",
              },

              {
                type: "textName",
                content: "【二、技术突破，填补空白】",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/7.jpg",
              },
              {
                type: "text",
                content:
                  "目前，港口码头大宗散货装船主要靠机械起重式抓斗，效率低且占用地轨起重空间。14520港口装船机填补了散货装船自动化智能化的空白，效率是传统抓斗三倍以上，是灵活移动、伸缩、升降等多功能一体机。不作业时可移到设备库，不占用集装箱地轨起重机作业空间。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/8.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/9.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/10.png",
              },
              {
                type: "text",
                content:
                  "14520 港口装船机是散料码头装船时使用的大型港口设备，带宽1400m，目前全国最大。机身总长52m，悬飘达到24.5m，最大抬升高度15m ；可旋转摆动布料，每组轮胎可±360°旋转；独立发电机，无需外接电源，供电稳定。全国首创，精细设计液压回路系统、液压控制系统，配备智能运行系统、智能避障系统、远程遥控监控系统，为生产安全作业保驾护航。",
              },
              {
                type: "textName",
                content: "【二、严格把关，铸造品质】",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/11.jpg",
              },
              {
                type: "text",
                content:
                  "秉持“过硬质量、精湛技术、高效服务”的核心理念，华南机械每一套设备从原料采购、加工制作、成品检测、出厂前测试及质检、到现场安装各个环节，都严格按照质检标准进行层层把关，以专业的技术和专注的态度铸就非凡品质。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/12.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/13.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/14.jpg",
              },
              {
                type: "text",
                content:
                  "14520 港口装船机以其高效的装卸能力，为港口物流提供了更为便捷、安全的服务，进一步推动了行业的智能化转型。在全球化的今天，高效的物流对于国家的经济发展至关重要。装船机技术突破，提高了港口的作业效率，提升了我国在国际物流领域的竞争力，为新质生产力在物流领域的发展树立了标杆。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/15.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/16.jpg",
              },
              {
                type: "text",
                content:
                  "从砂石骨料生产系统方案解决专家到港口移动输送机械，华南机械始终坚持“科技引领，创新驱动”的发展理念，致力于为客户提供最优质的解决方案。未来，华南机械将继续坚守以客户需求为导向，把好最后一道关,交好最后一把“钥匙”，保证设备顺利交付投产，助力港口装载能力迈上新台阶。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/17.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/18.png",
              },
            ],
          },
        },
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/1.png",
          title: "2024年华南机械两大新品发布会圆满举办，献礼国庆75周年",
          text: "国庆 75 周年到来之际，华南机械携两大新品震撼亮相第十届广州砂石展",
          day: "27",
          time: "2024-09",
          content: {
            newsDetalisTitle:
              "2024年华南机械两大新品发布会圆满举办，献礼国庆75周年",
            newsDetalis: [
              {
                type: "textName",
                content: "【2024华南机械两大新品发布会圆满举办】",
              },
              {
                type: "video",
                videoUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A%E8%A7%86%E9%A2%91.mp4",
              },
              {
                type: "textName",
                content: "【一、新品发布会】",
              },

              {
                type: "text",
                content:
                  "2024年9月24日，华南机械制造有限公司在广交会展馆隆重举办新品发布会，正式推出60120全球最大振动筛以及14520港口装船机两款具有划时代意义的产品，以此向即将到来的国庆75周年献上一份重磅礼物。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/2.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/10.6%E8%A3%85%E8%88%B9%E6%9C%BA%E5%87%BA%E5%8E%82/1.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/4.png",
              },
              {
                type: "text",
                content: "参加本次发布会的领导嘉宾有：",
              },
              {
                type: "text",
                content: "黄哲隽丨中交一公局第四工程有限公司",
              },
              {
                type: "text",
                content: "潘建锋丨中交产业投资控股有限公司",
              },
              {
                type: "text",
                content: "李华严丨中交四航(阳江)绿色矿业有限公司",
              },
              {
                type: "text",
                content: "黄文富丨中交四航(阳江)绿色矿业有限公司",
              },
              {
                type: "text",
                content: "廖忠昕丨阳江市世通绿色建材有限责任公司",
              },
              {
                type: "text",
                content: "赵  凌丨广东省砂石协会秘书长",
              },
              {
                type: "text",
                content: "钟连锋丨杭州海兴机械有限公司",
              },
              {
                type: "text",
                content: "袁晚华丨广州市一斗福机械设备有限公司",
              },
              {
                type: "text",
                content: "（排名不分先后）",
              },

              {
                type: "textName",
                content: "【二、开幕致辞】",
              },
              {
                type: "Title_img",
                content:
                  "华南机械制造有限公司总经理李红轩先生首先表达了对所有嘉宾的欢迎，并强调两款新品在国庆 75 周年之际发布的重大意义。他指出，这是公司响应新质生产力发展要求、推动民族工业崛起的具体行动。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/5.png",
              },
              {
                type: "Title_img",
                content:
                  "华南机械始终坚持“科技引领，创新驱动”的发展理念，致力于为客户提供最优质的解决方案。两款新品新品的发布，也是历经无数次试验与改进才得以面世。未来，华南机械将继续坚持以客户需求为导向，不断探索新技术、新模式，为推动砂石骨料行业发展贡献更多力量。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/6.png",
              },
              {
                type: "textName",
                content: "【三、产品宣讲】",
              },
              {
                type: "Title_img",
                content: "随后，品牌经理徐金荣小姐对两款新品进行详细宣讲。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/7.png",
              },
              {
                type: "Title_img",
                content: "1.60120全球最大振动筛 ",
                content:
                  "一直以来，国内振动筛领域在建国后从引进国外技术和设备起步，历经 75 年的不断模仿与改进。而今天，华南机械的 60120 振动筛惊艳亮相，打破了西方技术垄断，成为全球、全行业、首台国内自主研发制造的最大型振动筛，同时也是首台采用变频启动控制，首台全生命智能监测与控制的振动筛。这款振动筛的出现，具有重大的历史意义。它不仅是技术的突破，更是中国砂石行业走向世界的重要标志。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/8.png",
              },
              {
                type: "Title_img",
                content: "2.14520 港口装船机 ",
                content:
                  " 港口码头大宗散货装船目前主要靠起重式抓斗机，需要占用地轨起重空间的同时效率非常低。14520 港口装船机则填补了散货装船自动化智能化的空白，效率是传统抓斗三倍以上，集移动、伸缩、升降等功能于一体，不作业时可移到设备库，节省集装箱地轨起重机作业空间。它以高效装卸能力为港口物流提供便捷、安全服务，推动行业智能化转型，提高港口作业效率，增强我国在国际物流领域的竞争力，为新质生产力在物流领域的发展树立了标杆。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/9.png",
              },

              {
                type: "textName",
                content: "【四、启动仪式】",
              },
              {
                type: "text",
                content:
                  "发布会的高潮部分是启动仪式，随着主持人倒计时，嘉宾共同启动鎏金仪式，象征两大新品成功启航，现场气氛也达到顶点。",
              },
              {
                type: "text",
                content:
                  "在共进香槟环节，所有参与启动仪式的嘉宾共同举杯，庆祝新品的成功发布，祝愿祖国繁荣昌盛，同时寓意着华南机械将与国家同呼吸、共命运，共同迈向更加辉煌的未来。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/10.png",
              },
              {
                type: "textName",
                content: "【五、现场答疑】",
              },
              {
                type: "text",
                content:
                  "发布会结束后，媒体采访公司相关负责人和技术专家，深入了解产品研发过程、技术创新点、市场应用前景和公司未来发展战略等问题。",
              },
              {
                type: "text",
                content:
                  "华南机械总经理李红轩强调了公司在技术研发上的持续投入和对产品质量的严格把控，表达对市场的信心和为客户提供优质服务的决心。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/11.png",
              },
              {
                type: "text",
                content:
                  "展会现场还设置了咨询互动环节，为嘉宾提供深入了解新产品和设计理念的机会，更有详细资料和专业技术人员，随时解答疑问。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/12.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/13.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/14.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/15.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/16.jpg",
              },
              {
                type: "textName",
                content: "【六、圆满结束】",
              },
              {
                type: "text",
                content:
                  "此次发布会圆满结束，既是华南机械制造有限公司技术创新的展示，也是其在砂石骨料行业持续领导地位的有力证明。",
              },
              {
                type: "text",
                content:
                  "在国庆75周年即将到来之际，华南机械制造有限公司以60120振动筛和14520港口装船机两款新品的发布，向祖国献上了最诚挚的祝福。",
              },
              {
                type: "text",
                content:
                  "我们坚信，在国家的引领和民族的团结下，华南机械将继续以创新驱动引领行业发展，为全球砂石行业贡献更多的中国智慧和中国力量。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E6%96%B0%E5%93%81%E5%8F%91%E5%B8%83%E4%BC%9A/17.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/19.png",
              },
            ],
          },
        },
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/1.jpg",
          title: "【精彩回顾】华南机械亮相第十届广州国际砂石技术与设备展",
          text: "第十届广州砂石展完美落幕！华南机械带你回顾精彩瞬间",
          day: "27",
          time: "2024-09",
          content: {
            newsDetalisTitle: "第十届广州国际砂石技术与设备展圆满结束！",
            newsDetalis: [
              {
                type: "video",
                videoUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/%E7%A0%82%E7%9F%B3%E5%B1%95%E8%A7%86%E9%A2%91.mp4",
              },
              {
                type: "imgName",
                content: "（精彩抢先）",
              },
              {
                type: "text",
                content:
                  "为期三天（9月23日—25日）的第十届广州国际砂石技术与设备展圆满落幕。华南机械作为展会的重要参展商之一，推出了令人惊艳的创新产品以及领先的行业理念，不仅在展会上大放异彩，更以创新技术赢得了业界的广泛赞誉。",
              },
              {
                type: "textName",
                content: "【一、开幕仪式】",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/2.jpg",
              },
              {
                type: "text",
                content:
                  "本届展会以“携手发展新质生产力，蓄势赋能共筑新砂石”为主题，汇聚了来自全国矿业设备制造商和行业专家，共同探讨矿业装备的创新与发展。",
              },
              {
                type: "Title_img",
                content:
                  "华南机械凭借其在砂石技术与设备领域的深厚积累，展示了其在推动行业技术进步和产业升级中的领先地位。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/3.jpg",
              },
              {
                type: "Title_img",
                content:
                  "开幕仪式现场举行了企业签约仪式，华南机械拔得头筹，与五家企业现场签约，分别是阳江市世通绿色建材有限公司(中交一公局）、中交四航(阳江)绿色矿业有限公司、惠州港诚物流有限公司(惠州港）、紫金县金江实业有限公司、四会市辰达建材加工场。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/4.jpg",
              },
              {
                type: "Title_img",
                content:
                  "行业瞩目的砂石盛会，在开幕式上与国央企现场签约的设备厂家，仅华南机械一家，完美的阐述了华南机械在砂石骨料行业领先地位和强大影响力，始终以创新为核心引擎，不断突破技术壁垒，为行业发展注入新活力。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/5.jpg",
              },

              {
                type: "text",
                content:
                  "作为砂石骨料生产系统方案解决专家，华南机械专注于“3+1”的综合业务模式，其涵盖成套矿山设备研发制造与销售，成套砂石生产线系统方案设计/EPC项目总承揽，矿场的开采、加工和整套生产劳务总承包，绿色智慧矿山控制平台。",
              },
              {
                type: "textName",
                content: "【二、新品发布】",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/6.png",
              },
              {
                type: "text",
                content:
                  "在本次展会上，华南机械隆重推出两大新品，献礼国庆75周年，60120振动筛凭借全球最大尺寸、先进的技术和卓越的性能，一举成为展会的焦点",
              },
              {
                type: "text",
                content:
                  "作为中国首台液压移动升降伸缩装船机，14520港口装船机填补了散货装船自动化智能化的空白，效率是传统抓斗的三倍以上。",
              },
              {
                type: "text",
                content:
                  "展会现场还有无人机3D矿山扫描测绘技术和智慧矿山场景模拟等创新产品，这些展品不仅体现了华南机械对市场需求的深刻理解，也彰显了其在智能制造和绿色环保领域的不懈追求。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/7.JPG",
              },

              {
                type: "imgName",
                content: "（无人机3D矿山扫描测绘技术）",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/8.JPG",
              },
              {
                type: "imgName",
                content: "（智慧矿山场景模拟）",
              },
              {
                type: "textName",
                content: "【三、现场交流】",
              },
              {
                type: "text",
                content:
                  "展会期间，华南机械的展位吸引了众多客户和行业专家的关注，通过与客户的深入交流，华南机械不仅收获大批客户，更有机会和技术人员交流切磋。",
              },
              {
                type: "text",
                content:
                  "在这个充满活力的交流氛围中，华南机械不断汲取着养分，提升自身的技术水平和创新能力，为客户提供更加优质、高效的砂石骨料生产系统方案。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/9.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/10.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/11.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/12.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/13.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/14.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/15.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/16.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/17.jpg",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/18.jpg",
              },

              {
                type: "imgName",
                content: "（活动现场盛况）",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/19.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/%E7%A0%82%E7%9F%B3%E5%B1%95/20.png",
              },
            ],
          },
        },
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/1.png",
          title: "首次亮相！华南机械两大新品发布，9月23日定档广州砂石展！",
          text: "新品全球首发，行业交流盛会，华南机械，邀您共同见证",
          day: "23",
          time: "2024-09",
          content: {
            newsDetalisTitle:
              "首次亮相！华南机械两大新品发布，9月23日定档广州砂石展！",
            newsDetalis: [
              {
                type: "textName",
                content: "【一、诚挚邀请】",
              },
              {
                type: "text",
                content:
                  "华南机械制造有限公司亮相第十届广州国际砂石技术与设备展",
              },
              {
                type: "text",
                content: "时间：2024年9月23日-25日",
              },
              {
                type: "text",
                content: "地址：中国进出口商品交易会广交会展馆D区19.1号馆T201",
              },
              {
                type: "text",
                content: "新品全球首发，行业交流盛会",
              },
              {
                type: "text",
                content: "华南机械，邀您共同见证！",
              },

              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/2.png",
              },
              {
                type: "imgName",
                content: "（展位示意图）",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/3.png",
              },
              {
                type: "imgName",
                content: "（展位效果图）",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/4.png",
              },
              {
                type: "imgName",
                content: "（展会时间安排）",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/5.png",
              },
              {
                type: "imgName",
                content: "（扫码登记）",
              },
              {
                type: "textName",
                content: "【二、展会看点】",
              },
              {
                type: "Title_img",
                content: "1.60120全球最大振动筛首次亮相",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/6.png",
              },
              {
                type: "Title_img",
                content: "2.中国首台液压移动升降伸缩装船机展示",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/7.png",
              },
              {
                type: "Title_img",
                content: "3.无人机3D矿山扫描测绘技术",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/8.png",
              },
              {
                type: "Title_img",
                content: "4.智慧矿山场景模拟",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/9.png",
              },
              {
                type: "textName",
                content: "【三、两大新品发布会】",
              },

              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/10.png",
              },
              {
                type: "text",
                content: "发布会时间：2024年9月24日9:30-12:00",
              },
              {
                type: "text",
                content:
                  "发布会地点：中国进出口商品交易会广交会展馆D区19.1号馆T237",
              },
              {
                type: "text",
                content: "发布会流程：",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/11.png",
              },
              {
                type: "Title_img",
                title: "参展企业：",
                content:
                  "中电建、中国中铁、中铁南方（云浮）绿色建材、中交四航局、中交一公局、广物集团、东升控股集团、绿色矿业、广东广物金属矿业、韶关矿投宝铁、广西中驰新材料、汕尾市投控矿业、宏大爆破、谷城矿业、保宇矿业、浙交矿业、广东铁投物资、深圳市怡亚通供应链、广东新业混凝土、佛山市新利海混凝土、广州中航物资集团、客商银行、深向星辰、狮桥中国、小松、景津、徐工、宇通、三一、北宇、磊蒙、韶瑞、邵峰、临工重机、顺达、浙矿、怡状、海王、创世、双金、中联、同力、龙粤、凯瑞特、伟霄、德睿、世邦、辰渝鲁盛、亿通、天宏科技......",
              },
              {
                type: "Title_img",
                title: "媒体预约：",
                content:
                  "中矿业网、广东砂石协会、砂石骨料网、珠海市水泥制品行业协会、东莞市预拌混凝土协会、矿山机械网、广东电视台、优酷视频、腾讯视频、南方日报、今日头条......",
              },

              {
                type: "textName",
                content: "【四、交通指引】",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/12.png",
              },
              {
                type: "Title_img",
                title: "1.地铁",
                content:
                  "乘坐8号线新港东A出口，从6号门步行至登录大厅乘坐扶梯到珠江散步道",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/13.png",
              },
              {
                type: "Title_img",
                title: "2.自驾",
                content:
                  "高德导航至广交会展馆8号门，从8号门驶入D区地下停车场，停至负二层后乘坐电梯至珠江散步道（需支付停车费，前3小时收费5元，3小时后收费10元，45元/天封顶）",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/14.png",
              },
              {
                type: "Title_img",
                title: "3.公交",
                content:
                  "到达广交会展馆站的公交线路有：229、239、262、304、582、763、B7快线、旅游公交3线等。",
              },
              {
                type: "Title_img",
                title: "4.出租车及网约车",
                content:
                  "广州出租车起步价首3公里为人民币12元，超过3公里部分，每公里租价为人民币2.6元。网络用车请注意安全，避免用车高峰时段耽误您的行程。可导航至：广州·广交会展馆D区8号门地址：广州市海珠区阅江中路380号",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.22/15.png",
              },
            ],
          },
        },
        {
          img: "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%871.png",
          title: "展会资讯| 华南机械制造有限公司邀您参观第十届广州砂石展！",
          text: "2024华南机械最新研发成果“全球最大振动筛”，即将亮相广州砂石展T201，敬请期待",
          day: "22",
          time: "2024-09",
          content: {
            newsDetalisTitle:
              "展会资讯| 华南机械制造有限公司邀您参观第十届广州砂石展！",
            newsDetalis: [
              {
                type: "textName",
                content: "【一、展会看点】",
              },
              {
                type: "video",
                videoUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/WeChat_20240927164654.mp4",
              },
              {
                type: "text",
                content:
                  "2024华南机械最新研发成果！！！【60120振动筛】全球、全行业首台，自主研发的超大型设备，时隔六十年技术革新，引领行业新标准，产品性能遥遥领先，选择华南机械，开启筛分新纪元。",
              },

              {
                type: "textName",
                content: "【二、企业介绍】",
              },

              {
                type: "text",
                content:
                  "华南机械自2009年成立以来，专注于提供砂石骨料生产系统解决方案。作为“国家高新技术企业”和“专精特新认证企业”，华南机械注重技术创新和环保价值创造，拥有行业资质认证和100多项国家专利，公司通过设计、研发、制造、销售及服务砂石生产线设备，提升矿山机械行业的生产效率和产品质量。",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%872.png",
              },
              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%873.png",
              },

              {
                type: "imgName",
                content: "（企业荣誉和资质）",
              },

              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%874.png",
              },
              {
                type: "imgName",
                content: "（各项专利证书）",
              },

              {
                type: "img",
                content:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%875.png",
              },
              {
                type: "imgName",
                content: "（3+1主营业务模式）",
              },
              {
                type: "textName",
                content: "【三、产品介绍】",
              },

              {
                type: "text",
                content:
                  "产品范围：振动筛、破碎机、制砂机、洗砂机、脱水筛、皮带输送机、污水处理系统、3D无人机、智慧矿山等。",
              },
              {
                type: "Title_img",
                title: "1.PE颚式破碎机系列",
                content:
                  "该设备广泛应用于各种矿石与大块物料的大颗粒破碎，破碎比大，耗能低，结构简洁，维修便利。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%876.png",
                width: 800,
              },

              {
                type: "Title_img",
                title: "2.H多缸液压圆锥机系列",

                content:
                  "具有国际领先水平全液压圆锥破碎机，适用于各种矿石的中碎和细碎作业中，破碎效率高，粒型好",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%877.png",
                width: 800,
              },
              {
                type: "Title_img",
                title: "3.HN冲击式破碎机系列",

                content:
                  "国际化先进技术，是目前转速最快的破碎机；破碎整形一体机，满足多样需求；全液压装置，运行稳定，可靠性高",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%878.png",
                width: 800,
              },
              {
                type: "Title_img",
                title: "4.HEV双轴水平椭圆筛",

                content:
                  "物料运行轨迹为椭圆抛物线，透孔率高；双轴双电机，最大振幅达到16mm，筛分效率比普通振动筛高出30%。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%879.png",
                width: 800,
              },
              {
                type: "Title_img",
                title: "5.YKJ双轴圆振筛",

                content:
                  "双轴双电机，同步驱动；频率、振幅、安装角度可调；振力强，振动噪音小，故障率低。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8710.png",
                width: 800,
              },
              {
                type: "Title_img",
                title: "6.XSD叶轮洗砂机系列",
                width: 800,
                content:
                  "该设备用于砂石和矿渣颗粒的洗选，产量高，结构简洁，故障率低。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8711.png",
              },
              {
                type: "Title_img",
                title: "7.ZW振动给料机系列",
                width: 800,
                content:
                  "该设备广泛用于砂石骨料生产给料系统，可根据实际生产情况灵活调节均匀给料，并实现生产流程的集中控制和自动控制。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8712.png",
              },
              {
                type: "Title_img",
                title: "8.卸料闸阀",
                width: 800,
                content:
                  "该设备用于成品石料存储的卸料控制闸门，液压电动精准单独控制，液压动力足，开度大，维修方便。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8713.png",
              },
              {
                type: "Title_img",
                title: "9.移动输送机",
                width: 800,
                content:
                  "固定式输送机，拥有省内首条水平拐弯输送带制作安装技术；移动式输送机，可伸缩、可升降、可旋转摆动布料。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8714.png",
              },
              {
                type: "Title_img",
                title: "10.储料罐",
                width: 800,
                content:
                  "该设备用于存储成品、半成品石料的金属料罐，使用原材料进行现场制作安装，行业技术遥遥领先。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8715.png",
              },
              {
                type: "Title_img",
                title: "11.污水处理系统",
                width: 800,
                content:
                  "凭借丰厚的制砂洗砂生产线建设和运营经验，公司自主研发成套“洗砂污水处理系统”，工艺先进、技术精湛，为客户解决重点难点问题，深受好评。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8716.png",
              },
              {
                type: "Title_img",
                title: "12.3D无人机",
                width: 800,
                content:
                  "可用于矿山地形勘测，并实时回传数据、1:1地形还原，实景三维地图搭建破碎生产线，实现场景和实际场地的模拟融合。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8717.png",
              },
              {
                type: "Title_img",
                title: "13.智慧矿山",
                width: 800,
                content:
                  "以设备为中心，采用先进的信息技术和物联网技术，自动归集设备动态信息，可视化管控生产线设备的实时状况，设备管理更便利、高效。",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8718.png",
              },
              {
                type: "textName",
                content: "【三、部分案例现场 】",
              },
              {
                type: "Title_img",
                title: "1.广东肇庆4000t/h综合生产线EPC项目",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8719.png",
              },
              {
                type: "Title_img",
                title: "2.中交四航揭阳大南海350t/h生产线",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8720.png",
              },
              {
                type: "Title_img",
                title: "3.海南陵水800t/h生产线EPC项目",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8721.png",
              },
              {
                type: "Title_img",
                title: "4.广东河源紫金1000t/h生产线EPC项目",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8722.png",
              },
              {
                type: "Title_img",
                title: "5.中交广连高速十三标180t/h碎石生产线",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8723.png",
              },
              {
                type: "Title_img",
                title: "6.中交广连高速十四标180t/h碎石生产线",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8724.png",
              },
              {
                type: "Title_img",
                title: "7.广西武宣 600t/h碎石生产线 EPC项目",
                imgUrl:
                  "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83/%E5%B9%BF%E5%B7%9E%E5%B1%95%E9%A2%84%E7%83%AD/9.10/%E5%9B%BE%E7%89%8725.png",
              },
            ],
          },
        },
        {
          img: require("../assets/华南官网2.0素材/新闻中心/图片6.jpg"),
          title: "喜报|华南机械最新荣获实用新型专利证书",
          text: "实用新型名称：具有抑尘功能的砂石骨料多出口自动转换结构;",
          textTwo: "专利权人：华南机械制造有限公司",
          day: "06",
          time: "2024-08",
          content: {
            newsDetalisTitle:
              "实用新型名称：具有抑尘功能的砂石骨料多出口自动转换结构",
            newsDetalis: [
              {
                type: "textName",
                content: "【技术背景】",
              },
              {
                type: "text",
                content:
                  "砂石骨料是建筑和道路工程中广泛使用的一种重要材料。它主要由石子、沙子、碎石等组成，为了提高砂石骨料的运输、存储效率和使用效果，通常需要将砂石骨料进行分类存储，根据不同的颗粒级配要求，将砂石骨料按照不同粒径分别储存。",
              },

              {
                type: "text",
                content:
                  "目前，现有的砂石骨料分类存储装置在进行运输砂石骨料时，很容易在工作场所内扬起大量粉尘，从而对环境造成污染以及给人体带来不适。",
              },
              {
                type: "imgTwo",
                content: [
                  require("../assets/华南官网2.0素材/新闻中心/图片4.png"),
                  require("../assets/华南官网2.0素材/新闻中心/图片5.png"),
                ],
              },
              {
                type: "textName",
                content: "【实用新型内容】",
              },

              {
                type: "text",
                content:
                  "本实用新型的目的是为了解决现有技术中砂石骨料运输时容易扬起大量粉尘，会对环境造成污染以及给人体带来不适的问题，而提出的一种具有抑尘功能的砂石骨料多出口自动转换结构。",
              },
              {
                type: "text",
                content:
                  "该具有抑尘功能的砂石骨料多出口自动转换结构通过活塞盒、活塞板、复位弹簧、雾化喷头、弧形板以及第一磁板的设置，利用第一磁板与活塞板间的磁性相斥作用，持续性的向集尘管内喷洒水雾，以此与扫落进来的灰尘相结合，有效将灰尘进行吸收，提高了该装置的抑尘效果，确保了工作场所内环境的整洁性与舒适度。",
              },
              {
                type: "img",
                content: require("../assets/华南官网2.0素材/新闻中心/图片3.jpg"),
              },
              {
                type: "img",
                content: require("../assets/华南官网2.0素材/新闻中心/图片2.png"),
              },
              {
                type: "textName",
                content: "【企业荣誉】",
              },

              {
                type: "text",
                content:
                  "2009年企业创办至今获得设备生产技术专利和生产线技术专利证书100多项。华南机械先后成为中国砂石协会理事单位和绿色矿山产业联盟的常务理事单位，绿色矿山产业联盟技术委员会委员，并参与中国砂石行业规范（国家标准号:DZ/T-0316-2018）的制定，于2018年9月在广东股权交易中心挂牌（股权代码892335)。2018-2020年连续三年获得“广东省守合同重信用企业”荣誉，2020年通过国家高新技术企业认证，2023年通过ISO9000质量管理体系认证，2024年华南机械通过“专精特新企业认证”。",
              },
              {
                type: "img",
                content: require("../assets/华南官网2.0素材/新闻中心/图片1.jpg"),
              },
            ],
          },
        },
        {
          img: require("../assets/华南官网2.0素材/新闻中心/矩形 758.png"),
          title: "华南机械4000T/H砂石骨料项目建设现场",
          text: "项目建设无小事，小到基础建设大到投产运营，都是影响总体产能及稳定生产的决定性因素。那些隐藏在视觉之下的基础、焊接、安装角度等细节工艺，疏忽掉其中的一个细微的步骤，都会对生产线的质量大打折扣；从源头拒绝华而不实的“面子工程”，透过“魔鬼”看真相，打造内外兼修的大型生产线样板！",
          day: 28,
          time: "2022-12",
          content: {
            newsDetalisTitle: "华南机械4000T/H砂石骨料项目建设现场",
            newsDetalis: [
              {
                type: "text",
                content:
                  "项目建设无小事，小到基础建设大到投产运营，都是影响总体产能及稳定生产的决定性因素。 那些隐藏在视觉之下的基础、焊接、安装角度等细节工艺，疏忽掉其中的一个细微的步骤，都会对生产线的质量大打折扣； 从源头拒绝华而不实的“面子工程”，透过“魔鬼”看真相，打造内外兼修的大型生产线样板！",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915551053472.jpg",
              },
              {
                type: "text",
                content:
                  "看似寻常最奇崛，成如容易却艰辛。没有豪言壮语，他们是一群默默无闻的华南队伍，平凡而不平庸；梦想在前，路在脚下，拥有铁军之骄的机械之师，正用创新走出新路，用奉献成就绿色坦途的梦想!",
              },
              {
                type: "textName",
                content: "(筛分区围蔽)",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915569053103.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915605027264.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915622013124.jpg",
              },

              {
                type: "text",
                content:
                  "化身工地“蒙面焊匠”，利用专业的技术，遵照施工标准对每一个环节进行认真地施工。 着重安全施工的同时，用专业和匠心对待每一个环节与品质； 严守文明施工规范指南，落实日常作业安全保障。",
              },
              {
                type: "textName",
                content: "(半成品料仓)",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915639053595.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915661069334.jpg",
              },
              {
                type: "text",
                content:
                  "事无巨细,事必躬亲。 料仓的流型选择、进料口尺寸、卸料口尺寸、容量的大小、下料倾角、高度、给料机的选用、周边的辅助设计等都是重中之重，都逃不过巡检人员犀利的双眼； 稳扎硬性指标细节，严把工程质量，做到精益求精！",
              },

              {
                type: "textName",
                content: "(存储料罐 )",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915679075259.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915698089711.jpg",
              },
              {
                type: "text",
                content:
                  "施工现场按要求进行布置，水、电、土建基础等，项目组含各类施工人员且具备相应资质，储罐用的钢板、型材和附件符合设计要求。在建设过程中对储罐的预制、储罐的起升方式、组对安装过程以及储罐的焊接等工作进行全时监督。",
              },
              {
                type: "textName",
                content: "（输送架）",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915726073467.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915755053560.jpg",
              },
              {
                type: "text",
                content:
                  "①做好安全设计、风险评估，针对短路、防腐等情况拟定预防方案；②根据总设计方案做密闭罩设计、胶带回程带灰清扫等防尘设计方案；③针对日常的工作量加装断带保护方案。独特而稳定的输送带设计确保让物料输送畅通无阻",
              },
              {
                type: "textName",
                content: "基础建设 规划与交底",
              },
              {
                type: "text",
                content:
                  "有一个稳定和团结的队伍，从而保障在项目推进的情况下能按时完成设计、规划、安装、投产的主要工作；随时了解和完成客户的需求，为项目的总体进度提供保障。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915787085448.jpg",
              },
              {
                type: "text",
                content: "实时交流、反复论证",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915810046288.jpg",
              },
              {
                type: "text",
                content:
                  "围绕着工地施工准则，为品质重重把关、 科学管理、精细施工 对工艺与要点规范落实，精抓细节，对工程质量进行无盲点巡查与把控。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856915840024614.png",
              },

              {
                type: "text",
                content:
                  "精细化的施工管理，由表及里！管理组对现场的施工计划、施工进度、每日的安全教育及项目总结会议等工作进行细化和落实，让施工质量更加规范安全化、管理更加制度化；同时强化标准执行，打造标杆工地！",
              },
            ],
          },
        },
        {
          img: require("../assets/华南官网2.0素材/新闻中心/2.jpg"),
          title: "华南机械4000T/H砂石骨料项目建设现场",
          text: "我国砂石行业呈现环保化、大型化、智能化等发展趋势，高质量发展的诉求对砂石生产设备的环保、节能、高效、大产能、颗粒级配等方面提出了更高的要求。",
          day: 19,
          time: "2022-12",
          content: {
            newsDetalisTitle: '让传统破碎生产线跟上"环保、智能"新步伐',
            newsDetalis: [
              {
                type: "textTitle",
                content:
                  "我国砂石行业呈现环保化、大型化、智能化等发展趋势，高质量发展的诉求对砂石生产设备的环保、节能、高效、大产能、颗粒级配等方面提出了更高的要求。",
              },
              {
                type: "text",
                content:
                  "为使项目达到预期生产效果，实现既定产能，华南机械对破碎生产线提供全面的解决方案，达到自动化程度高、破碎率高，节能，产量大，生产出的石子粒度均匀、粒形好，适合公路桥梁等各种大中小工程项目建设的需求。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856851778000319.png",
              },

              {
                type: "textName",
                content: "环保方案",
              },
              {
                type: "text",
                content:
                  "物料破碎时，容易产生大量粉尘、噪音，因此破碎生产线的建设，必须达到环保要求，符合环保建设标准。华南机械通过封闭式设计，配置防尘罩、除尘器、喷淋设备，多管齐下，提升了综合生产线的环保性能，粉尘捕获率高达99%，更有效降低了噪音，打造了内外兼顾的高品质破碎砂石生产线。",
              },
              {
                type: "text",
                content:
                  "破碎生产线通过搭建厂房，有效阻断粉尘向空气中排放，也可降低噪音污染，对运输车辆密封覆盖以及对厂区安装喷雾除尘设备等进行有效控尘。",
              },
              {
                type: "text",
                content:
                  "针对破碎作业车间外溢的粉尘，可以配置气箱式脉冲袋收尘器对粉尘进行回收再利用；可在给料前端、筛料排料口配置雾化水喷淋，降低扬尘，同时在输送带输送环境配置密封罩，成品料建设料仓等。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856852711040817.jpg",
              },
              {
                type: "imgName",
                content: "(生产区围蔽)",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856852961041297.png",
              },
              {
                type: "imgName",
                content: "（密封式输送带)",
              },
              {
                type: "textName",
                content: "破碎设备的配置",
              },

              {
                type: "text",
                content:
                  "破碎生产线中，如石料规格尺寸较大，可选用华南机械颚式破碎机作为一级破碎。如遇对石料规格尺寸要求较严，需要由一定级配的石料组成时，则需配套使用破碎设备，如颚破、反击破或圆锥破组成的联合破碎产线。",
              },

              {
                type: "text",
                content:
                  "PE颚式破碎机采用挤压破碎工作原理，优化运动参数，破碎腔深、没有死区，且破碎比更大，不会发生堵料，有楔块调整排料口，减小了工人劳动强度和停机时间，粒度均匀、有助于二段破碎处理，同时电机座和主机一体化结构，使安装更加简便、节约占用空间。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853255026111.jpg",
              },
              {
                type: "imgName",
                content: "（颚式破碎机）",
              },

              {
                type: "text",
                content:
                  "PFQ重型反击式破碎机适用于如玄武岩、安山岩、河卵石、花岗岩等物料的破碎。采用新概念破碎技术，能满足不同物料规格破碎，完全满足“多碎少磨”新工艺要求，反击板与板锤间隙能方便调节，有效控制出料粒度，同时新型高耐磨材料大量使用，降低磨损件磨耗，延长更换周期、节约成本。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853424041956.jpg",
              },
              {
                type: "imgName",
                content: "(反击式破碎机)",
              },
              {
                type: "text",
                content:
                  "（PSC/PYB）圆锥破碎机更适用于较硬物料，如玄武岩、鹅卵石等，采用层压破碎原理，轧臼壁向破碎壁运动挤压物料，很大程度上减少了针片状物料，液压马达调整排料口调整操作方便，而且易损件耐磨性强、解决后期运行成本过高问题，增长使用寿命。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853570012886.jpg",
              },
              {
                type: "imgName",
                content: "（左1-PYB圆锥破碎机，右2-PSC圆锥破碎机）",
              },
              {
                type: "text",
                content:
                  "（PSC/HN制砂机系列，进料粒径≤50mm，采用“石打石”和“石打铁”工作模式，又有深腔叶轮的效率提高，处理得到0-5mm石子及沙子，所以成品粒形好、没有细纹，无粗细颗粒离析现象，细度模数可调，适合人工制砂和石子整形。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856853758087966.png",
              },
              {
                type: "imgName",
                content: "(冲击式破碎机)",
              },
              {
                type: "text",
                content:
                  "破碎生产线一般拥有长期料源，而性能稳定、高产能的设备就成为了大家重点考察因素。华南机械破碎设备组成的生产线极具稳定，处理能力强，产量高，能够处理各种复杂的建筑垃圾、骨料，应用范围极广。",
              },
              {
                type: "textName",
                content: "智慧智能  可视可控",
              },
              {
                type: "text",
                content:
                  "“减少用人数量，推动智能化。”是当下砂石生产线建设的新趋势。华南机械研发的‘智慧矿山’，解决了传统控制系统的诸多局限。在破碎生产线案例中不仅减少了用人数量，提高设备运转率，更提高了无故障连续运转时间，有效推动了传统企业实现数字化、智能化的转型。",
              },

              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856854091013631.jpg",
              },
              {
                type: "imgName",
                content: "（项目数据中心）",
              },
              {
                type: "textTitle",
                content:
                  "紧跟‘建设绿色智慧矿山’号召，华南机械砂石骨料项目纷纷建成投产，助力砂石骨料综合环保利用迈入规模化、集约化、智能化新阶段，成为行业发展、产业链延伸、改善生态环境角色中重要的一分子!",
              },
            ],
          },
        },
        {
          img: require("../assets/华南官网2.0素材/新闻中心/3.jpg"),
          title: "解密║机制砂生产线的升级改造方案",
          text: "对于机制砂生产线的建设与升级，我们有着成熟完整的 勘测选址、规划设计、设备安装、试机投产、售后无忧'的方案服务流程。",
          day: "02",
          time: "2022-12",
          content: {
            newsDetalisTitle: "解密║机制砂生产线的升级改造方案",
            newsDetalis: [
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856752847037492.png",
              },
              {
                type: "text",
                content:
                  "对于机制砂生产线的建设与升级，我们有着完整的‘勘测选址、规划设计、设备安装、试机投产、售后无忧的方案服务流程。",
              },
              {
                type: "textTitle",
                content: "华南机械助力贵州省贵定县200T/H机制砂生产线升级改造",
              },
              {
                type: "textName",
                content: "01",
              },
              {
                type: "textTitle",
                content: "勘测选址",
              },
              {
                type: "text",
                content:
                  "此生产线坐落于喀斯特地貌的贵州省境内，由于地形的特殊原因，原有的单一破碎生产线产能小、效益低。我们给到客户一条绿色环保洗砂制砂生产线的升级方案，在原有的破碎生产线的基础上，解决产能和收益上的负增长问题。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753087002305.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753289047939.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753499071002.jpg",
              },
              {
                type: "textName",
                content: "02",
              },
              {
                type: "textTitle",
                content: "规划设计",
              },
              {
                type: "text",
                content:
                  "素有‘地无三尺平’的大山里，需要在有限的空间创造预期的产能。扎营常驻与现场人员保持着密切交流，对场地的布局、产线的配置、前期的基建等现场工作进行可行性方案论证，第一时间给出合理且高效的方案。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753725097613.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856753905036072.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754104085630.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754232060588.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754459035843.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754589045865.jpg",
              },
              {
                type: "textName",
                content: "03",
              },
              {
                type: "textTitle",
                content: "设备安装",
              },
              {
                type: "text",
                content:
                  "结合原有的破碎生产线，再加一条制砂洗砂以及污水处理全套的产线，由制砂机到振筛筛分再到水洗。在考虑到后续的产线延伸的方案下，在制砂机旁预留一条破碎的产线可以用于单独锤破。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754711097237.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754810050524.jpg",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856754913037667.jpg",
              },
              {
                type: "textName",
                content: "04",
              },
              {
                type: "textTitle",
                content: "试机投产",
              },
              {
                type: "text",
                content:
                  "在产线投产过程中，由于产线当时的机修技术和管理各方面的欠缺，导致产线经常出现故障。我们针对此情况立即安排工程师驻场对操作人员指导和培训，我们的电气工程师也对整个产线的电路进行巡查和提供改进方案。",
              },
              {
                type: "img",
                content:
                  "https://47.105.187.47/ueditor/jsp/upload/image/20230220/1676856755007004539.jpg",
              },
              {
                type: "textName",
                content: "05",
              },
              {
                type: "textTitle",
                content: "售后无忧",
              },
              {
                type: "text",
                content:
                  "充足的配件、全天候的在线客服、专业的售后团队及产线方案解决专家，为您的设备和产能保驾护航！",
              },
            ],
          },
        },
      ],
    };
  },
  computed: {
    // 计算属性，返回 newsList 的前5个元素
    limitedNewsList() {
      const start = (this.current - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.newsList.slice(start, end);
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    gonewsDetails() {
      router.push(
        "./newDetails",
        () => {},
        () => {}
      );
    },
    gocase() {
      router.push("./case");
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
    },
    onChange(current, size) {
      this.current = current;
      this.pageSize = size;
      console.log(current, size, 1111111111);
    },
  },
};
</script>

<style lang="less" scoped>
.news_list {
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;

  .paging {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  .news_item {
    display: flex;
    justify-content: space-between;

    cursor: pointer;
    padding-bottom: 20px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 20px;
    .news_box {
      display: flex;
      > img:first-child {
        width: 360px;
        margin-right: 60px;
      }
    }

    .news_text {
      margin-top: 40px;
      // margin-left: 60px;
      margin-right: 80px;
      float: left;

      > div:first-child {
        font-size: 16px;
        font-weight: 800;
      }

      > div:last-child {
        margin-top: 30px;
        font-size: 14px;
        color: #999999;
      }
    }

    .news_time {
      display: flex;
      align-items: center;
      border-left: 1px solid #e9e9e9;
      padding-left: 46px;

      .time {
        > div:first-child {
          font-size: 36px;
          color: #999999;
          font-weight: 600;
          margin-bottom: 20px;
        }

        > div:last-child {
          font-size: 14px;
          color: #999999;
          white-space: nowrap;
        }
      }
    }
  }
}

.img {
  width: 100vw;
  height: 260px;
  background-image: url(../assets/华南官网2.0素材/新闻中心/矩形\ 3.png);
  background-size: 100% 100%;
  -webkit-animation: mov 5s infinite;
}

@-webkit-keyframes mov {
  from {
    transform: translateY(50px);
  }

  to {
    transform: translateY(0px);
  }
}

.imgbox {
  width: 60vw;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.P1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 30px;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 2.4px;
  text-align: center;
}

.daohan {
  width: 100vw;
  height: 50px;
  background: #f4f4f4;
}

.box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  line-height: 50px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  font-size: 14px;
}

/*  */
.gsnews {
  width: 60vw;
  margin: 0 auto;
  /* background-color: red; */
  margin-top: 50px;
  /* background-color: rgb(141, 214, 216); */
}

.gsnewmav {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #cccc;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.gsnewsBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: #52658d; */
  padding-bottom: 25px;
  border-bottom: 1px solid #cccc;
}

.gsnewsBox1 {
  width: 30%;
  height: 240px;
  /* background-image: url(); */
}

.gsnewsBox2 {
  width: 65%;
  height: 240px;
}

.Box2P1 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
}

.Box2P2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
}

.Box2P3 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
}

.gsnewsul {
  width: 100%;
  height: 125px;
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  /* 设置为纵向排列 */
  justify-content: space-between;
  /* 在Y轴上平分剩余空间 */
}

.item {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #121212;
}

.span {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #121212;
}

.gszxun {
  width: 100%;
  height: 40px;
  /* background-color: #80414b; */
  border-bottom: 1px solid #cccc;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 818px) {
  .img {
    width: 100vw;
    height: 130px;
  }

  .gsnews {
    width: 95%;
  }

  .gsnewsBox {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .gsnewsBox1 {
    width: 100%;
    height: 260px;
    /* background-color: #36b865; */
  }

  .gsnewsBox2 {
    width: 100%;
    height: 100%;
  }

  .Box2P1 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
  }

  .Box2P2 {
    /* font-size: ; */
  }
}

@media screen and (max-width: 821px) {
  .daohan {
    .box {
      width: 95%;
    }
  }

  .gsnews {
    width: 95dvw;
  }
}

.callme {
  margin-top: 50px;
  margin-bottom: 100px;
}
</style>
