<template>
  <div>
    <Nav></Nav>
    <div class="img">
      <div class="imgbox">
        <div data-wow-delay="0.2s" class="wow animate__fadeInDown imgboxtetx">
          <p class="imgboxtetxP1">
            无人机执行高精度地形数据测量<br />以科学数据合理设计产能最大化的生产解决方案
          </p>
          <div class="go">在线预约设计</div>
        </div>
      </div>
    </div>

    <div data-wow-delay="0.5s" class="wow slideInUp">
      <div class="MiddleParts">
        <div class="MiddlePart_nav">华南机械 > 3D矿山</div>
        <div class="MiddlePart">
          <div class="item" v-for="(item, index) in MiddleParts" :key="index">
            <div data-wow-delay="0.2s" class="wow animate__fadeInRight itemImg">
              <img
                style="width: 100%"
                :src="require(`../assets/华南官网2.0素材/3d矿山/${item.img}`)"
                alt=""
              />
            </div>
            <div
              data-wow-delay="0.2s"
              class="wow animate__fadeInRight itemText"
            >
              <p data-wow-delay="0.8s" class="wow animate__fadeInRight P1">
                {{ item.title }}
              </p>
              <p data-wow-delay="0.8s" class="wow animate__fadeInRight P2">
                以无人机采集地形数据，无惧地形复杂程度，最快2小时完成矿山地形勘测，并实时回传数据。免去原来人力勘测成本并高效缩短生产线设计周期。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <callme data-wow-delay="0.2s" class="wow animate__fadeInUp callme"></callme>
    <FTrecord
      data-wow-delay="0.2s"
      class="wow animate__fadeInUp FTrecord"
    ></FTrecord>
  </div>
</template>

<script>
import callme from "../components/callme.vue";
import FTrecord from "../components/FTrecord.vue";
import Nav from "../components/nav.vue";
import "wowjs/css/libs/animate.css";
import { WOW } from "wowjs";
export default {
  components: {
    Nav: Nav,
    // 结尾
    FTrecord: FTrecord,
    // 新闻资讯

    callme: callme,
  },
  data() {
    return {
      MiddleParts: [
        { title: "高效精准，地形数据实时传输", img: "矩形 13.png" },
        { title: "1:1矿山地形还原，设计方案科学合理", img: "1.png" },
        { title: "实景三维地图搭建破碎生产线", img: "2.png" },
        { title: "未遇见，先预见，防范施工错误", img: "3.png" },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      new WOW().init();
    });
  },
};
</script>

<style scoped>
.img {
  width: 100vw;
  height: 600px;
  background-image: url(../assets/华南官网2.0素材/3d矿山/矩形\ 3.png);
  background-size: 100% 100%;
}

.imgbox {
  position: relative;
  width: 60%;
  height: 100%;
  margin: 0 auto;
}

.imgboxtetx {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* width: 550px; */
  height: 100px;
  /* background-color: #8fd06c; */
}

.imgboxtetxP1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  line-height: 38px;
}

.go {
  width: 132px;
  height: 38px;
  background: #ff0000;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}

.MiddleParts {
  width: 100vw;
  /* height: 1092px; */
}

.MiddlePart_nav {
  /* width: 60%; */
  height: 50px;
  margin: 0 auto;
  background-color: #f4f4f4;
  padding-left: 20%;
  padding-right: 20%;
  text-align: left;
  line-height: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #333333;
}

.MiddlePart {
  width: 60%;
  height: 992px;
  /* background-color: red; */
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  /* 允许元素换行 */
  justify-content: space-between;
}

.item {
  width: 360px;
  /* height: 476px; */
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.2s;
  /* flex: 0 0 calc(33.3333% - 25px); */

  /* 每个元素占据 1/3 宽度减去一定的间距 */
}

.itemImg {
  width: 360px;
  height: 277px;
}

.itemText {
  width: 360px;
  height: 199px;
  background-color: #f5f5f5;
}

.P1 {
  font-size: 20px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Regular;
  font-weight: 400;
  text-align: left;
  color: #000000;
  line-height: 55px;
  text-align: center;
  font-weight: 700;
}

.P2 {
  font-size: 14px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Regular;
  font-weight: 400;
  text-align: justifyLeft;
  color: #333333;
  letter-spacing: 0.84px;
  padding-left: 10px;
  padding-right: 10px;
}

.P3 {
  position: relative;
  float: right;
  margin-right: 15px;
  margin-top: 35px;
  display: block;
  width: 100px;
  height: 35px;
  background: #b0b0b0;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Regular;
  font-weight: 400;
  /* text-align: justifyLeft; */
  /* color: #333333; */
  letter-spacing: 0.84px;
  overflow: hidden;
  color: aliceblue;
  text-shadow: 0 0 60px #fff;
}

.item:hover {
  box-shadow: 2px 2px 4px rgba(118, 118, 118, 0.5);
}

.callme {
  margin-bottom: 100px;
  margin-top: 50px;
}

@media all and (max-width: 821px) {
  /* img */
  .img {
    /* display:none; */
    width: 100vw;
    height: 180px;
    margin: 0 auto;
    background-size: 100% 100%;
  }

  .imgbox {
    width: 100%;
    margin: 0 auto;
    /* background-color:red; */
  }

  .imgboxtetxP1 {
    font-size: 16px;
    line-height: 28px;
  }

  .imgboxtetx .go {
    width: 100px;
    height: 28px;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
  }

  /* MiddleParts */
  .MiddleParts {
    width: 100vw;
  }

  .MiddlePart_nav {
    /* width:100%; */
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  .MiddlePart {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .MiddlePart .item {
    width: 100%;
    height: 450px;
  }

  .itemImg {
    width: 104%;
  }

  .itemText {
    width: 104%;
    margin-top: 25px;
  }

  /* callme */
  .callme {
    padding-top: 835px;
    /* display:none; */
  }

  .FTrecord {
    padding-top: 75px;
  }
}
</style>
