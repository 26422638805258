<template>
  <div
    :style="{
      height: '700px',
      overflow: 'hidden',
      position: 'relative',
      border: '1px solid #ebedf0',
      borderRadius: '2px',
      textAlign: 'center',
      background: '#fafafa',
    }"
  >
    <div id="container" style="width: 100%; height: 100%; overflow: hidden">
      <!-- <h1>hhhh</h1> -->
      <!-- <a-button type="primary" @click="showRevolve"> 开启自动旋转 </a-button>
      <a-button
        type="primary"
        @click="
          viewer.sceneManager.setBackground(
            'https://img2.baidu.com/it/u=1549063848,46470526&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1705424400&t=7a23ccd8ced7d7e1345d7dcfc3fe1c22'
          )
        "
      >
        开启网格
      </a-button>
      <a-button type="primary" @click="model.applyWireFrame(false)">
        关闭网格
      </a-button> -->
      <!-- <a-slider
        v-model="inputValue1"
        :min="0"
        :max="100"
        @change="onChange"
        @afterChange="onAfterChange"
      /> -->
      <div class="setBtn" @click="showDrawer">
        <div>模</div>
        <div>型</div>
        <div>设</div>
        <div>置</div>
      </div>
    </div>
    <a-drawer
      title="3D 模型设置"
      placement="left"
      :visible="visible"
      :mask="false"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
      @close="onClose"
    >
      <div class="form_box">
        <div class="item_btn">
          自动旋转
          <a-switch default-checked @change="revolvebtn" />
        </div>
        <div class="item_btn">
          显示网格
          <a-switch @change="showGridding" />
        </div>
        <div class="item_btn">
          显示尺寸
          <a-switch default-checked @change="showSizi" />
        </div>
        <div class="item_Split">
          模型拆分
          <a-slider
            v-model="inputValue1"
            :min="0"
            :max="100"
            @change="onSplit"
          />
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      param: {
        appkey: "PRDVAhxgdSUe",
        appsecret: "wDWbJknUps1gRGqTKE5Bar8Z4zV2lxYI",
      },
      viewer: null,
      model: null,
      defaultSet: true,
      inputValue1: 0,
      visible: false,
    };
  },
  mounted() {
    this.init();
    setTimeout(() => {
      // 延时后执行的代码
      //   this.viewer.sceneManager.setBackground("#afafaf");  //修改背景颜色
      this.viewer.sceneManager.setBackground(
        "https://southchinamachine.oss-cn-qingdao.aliyuncs.com/%E5%AE%98%E7%BD%91/%E5%AE%98%E7%BD%912.0/%E4%BA%A7%E5%93%81%E8%AF%A6%E6%83%85/details_bg.png"
      );
    }, 6000); // 2秒延时
  },
  watch: {
    defaultSet: {
      deep: true,
      immediate: true,
      handler(a, b) {
        this.$nextTick(() => {
          //   this.showRevolve();
          this.viewer.controls.startAutoRotate(1);

          //   this.model.showDimension(100, 100, 100);
        });
      },
    },
  },
  methods: {
    init() {
      this.viewer = new AMRT.Viewer(
        "container",
        /*容器dom或者dom对应的id*/ this.param
      );
      //通过老子云平台 ID 加载一个模型
      this.model = this.viewer.loadModel("1558391428957212672", {
        focus: true,
        onLoad: (m) => {
          m.showDimension(100, 100, 100);
        },
      });
    },
    showRevolve() {
      this.viewer.controls.startAutoRotate(1);
      this.model.showDimension(100, 100, 100);
    },
    revolvebtn(flag) {
      //旋转按钮
      flag
        ? this.viewer.controls.startAutoRotate(1)
        : this.viewer.controls.stopAutoRotate();
    },

    showSizi(flag) {
      //显示尺寸
      console.log(flag);
      flag
        ? this.model.showDimension(100, 100, 100)
        : this.model.hideDimension();
    },
    showGridding(flag) {
      //网格按钮
      this.model.applyWireFrame(flag);
    },

    onSplit(value) {
      console.log("change: ", value / 100);
      this.model.setExploding(value / 100);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
  },
};
</script>

<style>
.model-box {
  height: "700px";
  overflow: "hidden";
  position: "relative";
  border: "1px solid #ebedf0";
  background: "#fafafa";
}
.form_box {
  height: 260px;
}

.item_btn {
  height: 20%;
  display: flex;
  justify-content: space-between;
}

.item_Split {
  text-align-last: left;
  height: 20%;
}

.ant-drawer-wrapper-body {
  background-color: #6b6b6b;
  color: #fff;
}
.ant-drawer-header {
  background-color: #6b6b6b;
  color: #fff !important;
}

.ant-drawer-title {
  color: #fff;
}
.ant-drawer-close {
  color: #fff;
}
.setBtn {
  position: absolute;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  padding-top: 8px;
  padding: 8px 8px;
  padding-bottom: 8px;
  top: 40%;
  font-size: 16px;
  color: #fff;
  /* width: 26px; */
  background-color: #6b6b6b;
}
</style>
