<template>
  <div class="box">途观测试

    <div id="YiLiang" class="container">

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {

    }
  },
  mounted() {
    this.getToken()


  },
  methods: {

    // 获取场景token
    getToken() {
      axios({
        method: "post",
        url:
          "https://www.tuguan.net/api/user/v1/visitorScene/77hq222y"

      }).then((res) => {


        this.init(res.data.accessToken);

      });
    },
    // 初始化加载图观三维场景服务
    init(token) {
      // 初始化图观App
      console.log('初始化');
      window.appInstance = new TGApp.App();
      window.appInstance.initService(
        {
          container: document.getElementById('YiLiang'),
          mode: 'scene',
          token: token,
          url: 'https://www.tuguan.net/publish/scene/api/77hq222y',
          resourceBasePath: 'https://www.tuguan.net/public/tgapp/scene',
        },
        (result) => {
          console.log(result);

          if (result.result == 1) {
            // //添加TGAPI事件回调，图观场景服务初始化成功后
            // // 隐藏遮罩
            // this.pickModel();
            // this.clickModel();
            // // CameraTipClcik()

            // this.showSceneInfo();
            // this.getStates()
            // this.getModelData()
            // this.RestrictedPerspective()

            this.showLoading = false;





          }
        }
      );




    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  color: #fff;
}

.container {
  width: 100vh;
  height: 100vh;
}
</style>
