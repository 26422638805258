<template>
  <div>
    <Nav></Nav>
    <div class="banner">
      <div class="wow animate__fadeInDown title" data-wow-delay="0.3s">
        <div class="banner_title">砂石骨料生产系统解决方案专家</div>
        <div class="banner_title2">
          促进科技与传统的融合，助力砂石产能最大化、行业数字化变革
        </div>
      </div>
      <img src="../assets/首页/矩形 3.png" alt="Lazy-loaded image" />
    </div>
    <div class="intro">
      <div class="intro_1">
        <div
          data-wow-delay="0.3s"
          class="wow animate__fadeInRight intro_1_title"
        >
          国家高新技术企业
        </div>
        <div
          data-wow-delay="0.3s"
          class="wow animate__fadeInRight intro_1_content"
        >
          华南机械制造有限公司
        </div>
        <div
          data-wow-delay="0.3s"
          class="wow animate__fadeInRight intro_1_content"
        >
          砂石骨料生产系统解决方案设计
        </div>
        <div
          data-wow-delay="0.3s"
          class="wow animate__fadeInRight intro_1_content"
        >
          成套矿山设备研发制造与销售
        </div>
        <div
          data-wow-delay="0.3s"
          class="wow animate__fadeInRight intro_1_content"
        >
          成套砂石生产线的安装建设/EPC项目承揽
        </div>
        <div
          data-wow-delay="0.3s"
          class="wow animate__fadeInRight intro_1_content"
        >
          矿场的开采、加工和整套生产劳务总承包
        </div>
        <div
          data-wow-delay="0.3s"
          class="wow animate__fadeInRight intro_1_content"
        >
          绿色智慧矿山的构建
        </div>
      </div>
      <div class="intro_2">
        <img
          data-wow-delay="0.8s"
          class="wow animate__fadeInRight"
          src="../assets/首页/矩形 4.png"
          alt="Lazy-loaded image"
        />
      </div>
    </div>

    <div class="solution">
      <div class="solution_box">
        <div class="solution_box_1" style="background-color: #f9f9f9">
          <div class="solution_textbox">
            <div
              data-wow-delay="0.8s"
              class="wow animate__fadeInRight solution_box_lef"
            >
              <img src="../assets/首页/解决方案/89.png" alt="" />
            </div>
            <div
              data-wow-delay="0.8s"
              class="wow animate__fadeInRight solution_box_leftext"
            >
              解决方案
            </div>
          </div>
          <div
            data-wow-delay="0.8s"
            class="wow animate__fadeInRight solution_textbox_fontsize"
          >
            矿山3D扫描技术是推进矿山机械行业数字化变革发展的重要信息化技术，华南机械是目前全国唯一开拓并成熟应用3D矿山扫描技术的先行者。3D矿山扫描技术，利用其强大的空间信息处理和分析能力，以数字化直观逼真还原矿山地形地貌，为行业客户在矿山生产线解决方案的设计过程中提供高效、精准、可靠的数据支持。
          </div>
        </div>
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight solution_box_1"
        >
          <img src="../assets/s.png" alt="Lazy-loaded image" />
          <div
            data-wow-delay="0.8s"
            class="wow animate__fadeInRight solution_box_2"
          >
            <div class="solution_box_3">砂石骨料生产系统解决方案设计</div>
          </div>
        </div>
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight solution_box_1"
        >
          <img
            src="../assets/首页/解决方案/矩形 7 拷贝5.png"
            alt="Lazy-loaded image"
          />
          <div
            data-wow-delay="0.8s"
            class="wow animate__fadeInRight solution_box_2"
          >
            <div class="solution_box_3">成套矿山设备研发制造与销售</div>
            <!-- <span><img src="../assets/首页/解决方案/形状 2.png" alt="" /></span> -->
          </div>
        </div>
      </div>
      <div class="solution_box">
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight solution_box_1"
        >
          <img
            src="../assets/首页/解决方案/矩形 7 拷贝 6.png"
            alt="Lazy-loaded image"
          />
          <div
            data-wow-delay="0.8s"
            class="wow animate__fadeInRight solution_box_2"
          >
            <div class="solution_box_3">
              成套砂石生产线的安装建设/EPC项目承揽
            </div>
          </div>
        </div>
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight solution_box_1"
        >
          <img
            src="../assets/首页/解决方案/矩形 7 拷贝7.png"
            alt="Lazy-loaded image"
          />
          <div
            data-wow-delay="0.8s"
            class="wow animate__fadeInRight solution_box_2"
          >
            <div class="solution_box_3">
              矿场的开采、加工和整套生产劳务总承包
            </div>
            <!-- <span><img src="../assets/首页/解决方案/形状 2.png" alt="" /></span> -->
          </div>
        </div>
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight solution_box_1"
        >
          <img
            src="../assets/首页/解决方案/矩形 7 拷8.png"
            alt="Lazy-loaded image"
          />
          <div
            data-wow-delay="0.8s"
            class="wow animate__fadeInRight solution_box_2"
          >
            <div class="solution_box_3">绿色智慧矿山的构建</div>
            <!-- <span><img src="../assets/首页/解决方案/形状 2.png" alt="" /></span> -->
          </div>
        </div>
      </div>
      <div class="solution_box">
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight solution_box_1"
        >
          <div class="solution_box_4">
            <span
              ><img
                src="../assets/首页/解决方案/矩形 7 拷贝 10.png"
                alt="Lazy-loaded image"
            /></span>
            <span
              ><img
                src="../assets/首页/解决方案/矩形 7 拷贝 11.png"
                alt="Lazy-loaded image"
            /></span>
          </div>
          <div class="solution_box_4">
            <span
              ><img
                src="../assets/首页/解决方案/矩形 7 拷贝 12.png"
                alt="Lazy-loaded image"
            /></span>
            <span
              ><img
                src="../assets/首页/解决方案/矩形 7 拷贝 13.png"
                alt="Lazy-loaded image"
            /></span>
          </div>
        </div>
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight solution_box_1"
        >
          <div class="solution_box_4">
            <span
              ><img
                src="../assets/首页/解决方案/矩形 7 拷贝 14.png"
                alt="Lazy-loaded image"
            /></span>
            <span
              ><img
                src="../assets/首页/解决方案/矩形 7 拷贝 16.png"
                alt="Lazy-loaded image"
            /></span>
          </div>
          <div class="solution_box_4">
            <span
              ><img
                src="../assets/首页/解决方案/矩形 7 拷贝 8.png"
                alt="Lazy-loaded image"
            /></span>
            <span
              ><img src="../assets/首页/解决方案/9.png" alt="Lazy-loaded image"
            /></span>
          </div>
        </div>
        <div class="solution_box_1" style="background-color: #f9f9f9">
          <div style="height: 52px">
            <div
              data-wow-delay="0.8s"
              class="wow animate__fadeInRight solution_box_rigtext"
            >
              物料加工
            </div>
            <div
              data-wow-delay="0.8s"
              class="wow animate__fadeInRight solution_box_rig"
            >
              <img
                src="../assets/首页/解决方案/90.png"
                alt="Lazy-loaded image"
              />
            </div>
          </div>
          <div
            style="
              font-size: 14px;
              text-align: left;
              float: right;
              margin-right: 52px;
            "
            data-wow-delay="0.8s"
            class="wow animate__fadeInRight"
          >
            <ul style="float: left; list-style: none">
              <li>花岗岩</li>
              <li>鹅卵石</li>
              <li>安山岩</li>
              <li>蛇纹岩</li>
              <li>钢渣</li>
            </ul>
            <ul style="float: left; margin-left: 20px; list-style: none">
              <li>玄武岩</li>
              <li>石灰石</li>
              <li>辉绿岩</li>
              <li>铁矿石</li>
              <li>建筑垃圾</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品中心 -->
    <div class="ProductCenter" v-if="ProdFlag">
      <div class="ProductCenterBox">
        <div class="navbox">
          <div class="left">产品中心</div>
          <div class="rigth">
            <div
              class="ProductCenterBoxitem"
              v-for="(item, index) in ProductCenter"
              :key="index"
              @click="productCenter(index)"
              :style="{ backgroundColor: item.bgc }"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="butbox">
          <div
            class="item"
            v-for="(item, index) in data"
            :key="index"
            @click="productdetai(item.id, item.title)"
          >
            <div class="item_box">
              <div
                data-wow-delay="0.2s"
                class="wow animate__fadeInRight item_box_img"
              >
                <div
                  class="imgbox"
                  style="width: 80%; height: 100%; margin: auto"
                >
                  <img
                    style="width: 100%; height: 100%"
                    :src="
                      require(`../assets/华南官网2.0素材/首页产品/${item.img}`)
                    "
                    alt="Lazy-loaded image"
                  />
                </div>
              </div>

              <div
                data-wow-delay="0.8s"
                class="wow animate__fadeInRight item_box_title"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 工程案例 -->
    <div class="EngineeringCases">
      <div class="EngineeringCase">
        <div class="navbox">
          <p class="navboxP">工程案例</p>
          <p
            style="
              text-align: right;
              font-size: 14px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              line-height: 75px;
              cursor: pointer;
            "
            @click="gocase"
          >
            查看更多
          </p>
        </div>
        <div class="butbox">
          <div
            class="butboximg"
            v-for="(item, index) in EngineeringCases"
            :key="index"
            @click="engineeringCases(index)"
          >
            <!-- ../assets/华南官网2.0素材/首页/工程案例/${item.img} -->
            <img
              :src="
                require(`../../public/华南官网2.0素材/项目案例/${item.img}`)
              "
              style="width: 100%; height: 100%"
              alt="Lazy-loaded image"
              data-wow-delay="0.2s"
              class="wow animate__fadeInRight"
            />
            <div
              data-wow-delay="0.8s"
              class="wow animate__fadeInRight butboxbut"
              style="z-index: 1"
            >
              <p class="butboxbutP">{{ item.title }}</p>
              <span class="butboxbutSP"
                ><img
                  src="../assets/华南官网2.0素材/首页/工程案例/形状 2.png"
                  style="width: 35px; height: 35px; margin-top: 5px"
                  alt="Lazy-loaded image"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <NewsInquiry></NewsInquiry> -->
    <FTrecord data-wow-delay="0.2s" class="wow animate__fadeInUp"></FTrecord>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import NewsInquiry from "../components/NewsInquiry.vue";
import callme from "../components/callme.vue";
import router from "@/router";

import "wowjs/css/libs/animate.css";
import { WOW } from "wowjs";
export default {
  name: "HomeView",
  components: {
    Nav: Nav,
    // 结尾
    FTrecord: FTrecord,
    // 新闻资讯
    NewsInquiry: NewsInquiry,
    callme: callme,
  },
  data() {
    return {
      // 产品中心
      ProdFlag: true,
      ProductCenter: [
        { name: "", title: "破碎系列", bgc: "#cf000e" },
        { name: "", title: "筛分系列", bgc: "#cccccc" },
        { name: "", title: "制砂系列", bgc: "#cccccc" },
        { name: "", title: "洗砂系列", bgc: "#cccccc" },
        { name: "", title: "污水处理", bgc: "#cccccc" },
        { name: "", title: "输送系统", bgc: "#cccccc" },
      ],
      // ../assets/华南官网2.0素材/产品中心/${item.img}`
      ProductCenterImg: [
        { id: "0", title: "PE颚式破碎机", img: "破碎/PE颚式破碎机.png" },
        {
          id: "4",
          title: "H多缸液压圆锥破碎机",
          img: "破碎/H多缸液压圆锥机.png",
        },
        { id: "8", title: "PF反击式破碎机", img: "破碎/PF反击式破碎机.png" },
        {
          id: "9",
          title: "PFQ强力反击式破碎机",
          img: "破碎/PFQ强力反击式破碎机.png",
        },
        {
          id: "3",
          title: "PSC弹簧圆锥破碎机",
          img: "破碎/PSC弹簧圆锥破碎机.png",
        },
      ],
      Sieving: [
        { id: "10", title: "ZW振动给料机", img: "筛分/ZW振动给料机.png" },
        { id: "11", title: "YKJ振动筛", img: "筛分/YKJ振动筛.png" },
        { id: "12", title: "SL重型振动筛", img: "筛分/SL重型振动筛1.png" },
      ],
      SandMaking: [
        // { id: "15", title: "PCX重型锤式破碎机", img: "制砂/PCX锤式制砂机.png" },
        { id: "15", title: "HN冲击式破碎机", img: "制砂/HN冲击式制砂机.png" },
      ],
      WashedSand: [
        { id: "13", title: "SL超级脱水筛", img: "筛分/sl超级脱水筛1.png" },
        {
          id: "14",
          title: "HNZ系列细砂回收一体机",
          img: "筛分/HN电动马达振动筛.png",
        },
        { id: "16", title: "XSD叶轮洗砂机", img: "洗砂/XSD叶轮洗砂机.png" },
        { id: "17", title: "LSX螺旋洗砂机", img: "洗砂/LSX螺旋洗砂机.png" },
        {
          id: "18",
          title: "HNZ侧板激振器脱水筛",
          img: "洗砂/HN超级脱水筛.png",
        },
      ],
      sewage: [
        { id: "19", title: "板框压滤机", img: "污水处理/板框压滤机.png" },
      ],
      transport: [
        { id: "20", title: "DTII(A)型带式输送机", img: "输送系统/托辊.png" },
      ],

      // 工程案例
      EngineeringCases: [
        {
          id: "0",
          name: "4000t/h碎石生产线",
          img: "1.png",
          title: "4000t/h碎石生产线",
        },
        {
          id: "1",
          name: "1000t/h碎石生产线",
          img: "2.png",
          title: "1000t/h碎石生产线",
        },
        {
          id: "2",
          name: "800t/h碎石生产线",
          img: "3.png",
          title: "800t/h碎石生产线",
        },
        {
          id: "3",
          name: "中交四航局 350t/h碎石生产线",
          img: "4.png",
          title: "中交四航局 350t/h碎石生产线",
        },
        {
          id: "4",
          name: "中交广连高速十一标180t/h碎石生产线",
          img: "5.png",
          title: "中交广连高速十一标180t/h碎石生产线",
        },
        {
          id: "5",
          name: "中交广连高速十四标180t/h碎石生产线",
          img: "6.png",
          title: "中交广连高速十四标180t/h碎石生产线",
        },
      ],
      data: [],
      name: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateClassName);
    this.updateClassName(); // 初始化时更新类名
    this.data = this.ProductCenterImg;

    // wow动画
    this.$nextTick(() => {
      new WOW().init();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateClassName); // 移除窗口大小变化的监听器
  },
  methods: {
    productCenter(index) {
      this.ProductCenter.forEach((item, i) => {
        if (i === index) {
          item.bgc = "#cf000e";
        } else {
          item.bgc = "#cccccc";
        }
      });
      if (index == 0) {
        this.data = this.ProductCenterImg;
      } else if (index == 1) {
        this.data = this.Sieving;
      } else if (index == 2) {
        this.data = this.SandMaking;
      } else if (index == 3) {
        this.data = this.WashedSand;
      } else if (index == 4) {
        this.data = this.sewage;
      } else if (index == 5) {
        this.data = this.transport;
      }
    },

    updateClassName() {
      this.ProdFlag = window.innerWidth > 1550 ? true : false;
    },
    productdetai(id, crushText) {
      localStorage.setItem("productid", id);

      this.$router.push({
        name: "details",
        params: {
          id: id,
          crushText: crushText,
        },
      });
    },
    gocase() {
      router.push("./case");
    },
    engineeringCases(index) {
      let titleText = this.EngineeringCases[index].title;
      localStorage.setItem("CaseId", index);
      this.$router.push({
        name: "CaseDetails",
        params: {
          id: index,
          CaseText: titleText,
        },
      });
    },
  },
};
</script>
<style scoped>
.banner {
  position: relative;
  height: 600px;
  z-index: -1;
  -webkit-animation: mov 5s infinite;
}

@-webkit-keyframes mov {
  from {
    transform: translateY(50px);
  }

  to {
    transform: translateY(0px);
  }
}

.title {
  width: 100%;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 30%;
}

.banner img {
  width: 100%;
  height: 100%;
}

.banner_title {
  font-size: 48px;
  letter-spacing: 10px;
}

.banner_title2 {
  margin-top: 20px;
  font-size: 24px;
}

.intro {
  width: 60%;
  height: 380px;
  margin: 0 auto;
  margin-top: 70px;
}

.intro_1 {
  display: inline-block;
  width: 50%;
}

.intro_2 {
  width: 50%;
  height: 80%;
  display: inline-block;
}

.intro_2 img {
  width: 100%;
  height: 100%;
}

.intro_1_title {
  font-size: 24px;
  text-align: left;
}

.intro_1_content {
  font-size: 18px;
  margin-top: 25px;
  text-align: left;
}

.solution {
  width: 100%;
  height: 725px;
  background: #f9f9f9;
}

.solution_box {
  width: 60%;
  margin: 0 auto;
  display: flex;
}

.solution_box_1 {
  width: 33.333%;
  height: 240px;
  background-color: aliceblue;
  float: left;
  position: relative;
  overflow: hidden;
  margin: 1px;
}

.solution_textbox {
  height: 52px;
  margin-bottom: 20px;
}

.solution_textbox_fontsize {
  font-size: 14px;
}

.solution_box_1 img {
  width: 100%;
  height: 100%;
}

.solution_box_2 {
  width: 100%;
  height: 42px;
  line-height: 45px;
  position: absolute;
  color: #ffffff;
  margin-top: -48px;
  text-align: center;
}

.solution_box_2 span {
  display: inline-block;
  width: 10%;
  background-color: black;
}

.solution_box_3 {
  opacity: 0.5;
  background-color: black;
  float: left;
  width: 100%;
  overflow: hidden;
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 使用点号代替文本溢出部分 */
}

.solution_box_2 span img {
  width: 100%;
  height: 100%;
}

.solution_box_leftext {
  font-size: 24px;
  float: left;
  margin-left: 5%;
  line-height: 52px;
}

.solution_box_rigtext {
  font-size: 24px;
  margin-bottom: 20px;
  float: left;
  margin-left: 50%;
  line-height: 52px;
}

.solution_box_lef {
  width: 52px;
  height: 52px;
  display: inline;
  float: left;
}

.solution_box_rig {
  width: 52px;
  height: 52px;
  display: inline;
  float: right;
}

.solution_box_rig img {
  width: 100%;
}

.solution_box_1 ul li {
  margin: 10px auto;
}

.solution_box_4 {
  display: flex;
}

.solution_box_4 span {
  display: inline-block;
  flex: 1;
  height: 118px;
  margin: 1px;
}

/* 产品中心 */
.ProductCenter {
  width: 100vw;
  height: 260px;
  background: #ffffff;
}

.ProductCenterBox {
  width: 60%;
  height: 363px;
  margin: 50px auto;
}
.navbox {
  position: relative;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: space-between;
}
.left {
  width: 96px;
  height: 55px;
  font-size: 24px;
  color: #121212;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  line-height: 55px;
}

.rigth {
  display: flex;
  justify-content: right;
  justify-content: space-between;
  width: 800px;
  height: 55px;
  float: right;
  line-height: 55px;
}
.ProductCenterBoxitem {
  width: 20%;
  height: 55px;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

/* .butbox {
  width: 100%;
  height: 500px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  background-color:pink
} */
.item {
  position: relative;
  height: 50%;
  top: 15px;
  flex-grow: 0.25;
  margin-left: 2px;
  cursor: pointer;
}
.item_box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 228px;
  height: 70%;
}
.item_box_img {
  width: 100%;
  height: 100%;
}

.item_box_title {
  width: 100%;
  height: 20%;
  text-align: center;
  line-height: 65px;
}

/* 工程案例 */
.EngineeringCases {
  background: #f9f9f9;
  margin-top: 150px;
  margin-bottom: 35px;
}
.EngineeringCase {
  width: 60%;
  height: 600px;
  margin: auto;
}
.navbox {
  width: 100%;
  height: 69px;
}
.navboxP {
  width: 150px;
  height: 24px;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #121212;
  line-height: 69px;
}
.butbox {
  width: 100%;
  height: 530px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.butboximg {
  flex: 0 0 33.33%;
  box-sizing: border-box;
  height: 265px;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.butboxbut {
  position: relative;
  bottom: 50px;
  width: 100%;
  height: 46px;
  opacity: 0.6;
  background: #2a2a2a;
  z-index: 10;
}
.butboxbutP {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 262px;
  height: 24px;
  color: #f9f9f9;
  z-index: 100;
  overflow: hidden;
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 使用点号代替文本溢出部分 */
}

.butboxbutSP {
  position: absolute;
  right: 0px;
  display: inline-block;
  width: 46px;
  height: 46px;
  background: #000000;
}
@media all and (max-width: 825px) {
  .banner {
    height: 100%;
  }

  .title {
  }

  .banner_title {
    font-size: 20px;
  }

  .banner_title2 {
    font-size: 16px;
  }

  .intro {
    width: 95%;
    height: 100%;
    margin-top: 0px;
  }

  .intro_1 {
    width: 50%;
    margin: auto;
  }

  .intro_1_title {
    font-size: 16px;
  }

  .intro_1_content {
    font-size: 12px;
    margin-top: 1%;
  }

  .intro_2 {
    width: 50%;
    margin: auto;
  }

  .solution {
    height: 100%;
  }

  .solution_box {
    width: 95%;
    /* flex-direction: column; */
  }

  .solution_box_1 {
    width: 100%;
  }

  .solution_textbox_fontsize {
    line-height: 25px;
  }

  .solution_box_3 {
    font-size: 12px;
  }

  .EngineeringCases {
    margin-top: 25px;
  }
  .EngineeringCase {
    width: 95%;
    height: 100%;
  }
  .butboximg {
    width: 100%;
    height: 100%;
  }
  .butbox {
    height: 100%;
    flex-direction: column;
  }
}

@media all and (max-width: 414px) {
  .intro_2 img {
    height: 165px;
  }

  .banner_title {
    font-size: 15px;
  }

  .banner_title2 {
    font-size: 12px;
  }

  .solution_box {
    flex-direction: column;
  }
}

@media screen and (min-width: 1921px) {
  .item_box {
    height: 90%;
  }
  .EngineeringCase {
    margin-bottom: 150px;
  }
  .butboximg {
    height: 300px;
  }
  .solution {
    height: 800px;
  }
  .solution_box_1 {
    height: 265px;
  }
  .solution_box_4 span img {
    height: 140px;
  }

  .intro {
    height: 420px;
  }
  .intro_2 {
    height: 90%;
  }
  .intro_2 img {
    height: 100%;
  }
  .intro_1_title {
    font-size: 36px;
  }
  .intro_1_content {
    font-size: 20px;
  }
  .EngineeringCases {
    height: 670px;
    margin-bottom: 50px;
  }
  .butboxbutP {
  }
}
</style>
