import Vue from "vue";
import VueRouter from "vue-router";
// import axios from 'axios';
import HomeView from "../views/HomeView.vue";
import DMine from "../views/3DMine.vue";
import news from "../views/news.vue";
import WalkInto from "../views/Walklnto.vue";
import US from "../views/ContactUs.vue";
import Technical from "../views/Technical.vue";
// 详情页
import Details from "../views/Product_Details.vue";
import CaseDetails from "../views/Case_Detailt.vue";
import newDetails from "../views/news_Detalis.vue";
import test from "../views/test.vue";

import testa from "../views/a.vue";
import modelDetails from "../views/modelDetails.vue";

import boss from "@/components/boss.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/product",
    name: "Productcenter",
    component: () => import("../views/Productcenter.vue"),
  },
  {
    path: "/case",
    name: "Case",
    component: () => import("../views/Case.vue"),
  },
  {
    path: "/mine",
    name: "mine",
    component: DMine,
  },
  {
    path: "/news",
    name: "news",
    component: news,
  },
  {
    path: "/walk",
    name: "walk",
    component: WalkInto,
  },
  {
    path: "/us",
    name: "us",
    component: US,
  },
  {
    path: "/Technical",
    name: "Technical",
    component: Technical,
  },
  // 产品详情页
  {
    path: "/details/:crushText",
    name: "details",
    component: Details,
    // props:true//props传参
  },

  //项目详情页
  {
    path: "/CaseDetails/:CaseText",
    name: "CaseDetails",
    component: CaseDetails,
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },
  // 新闻详情页
  {
    path: "/newDetails/:content",
    name: "newDetails",
    component: newDetails,
  },
  //3D模型全屏页面
  {
    path: "/modelDetails/:id?",
    name: "modelDetails",
    component: modelDetails,
  },

  {
    path: "/testa",
    name: "testa",
    component: testa,
  },

  // {
  //   path: "/a",
  //   name: "a",
  //   component: a,
  //   children: [
  //     {
  //       path: "components/boss",
  //       name: "boss",
  //       component: boss,
  //       meta: {
  //         requireAuth: true,
  //       },
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
