<template>
  <div>
    <Nav></Nav>
    <div class="walkimg">
      <div class="box">
        <div class="boxtext">
          <p class="P1">走进华南</p>
          <p class="P2">
            致力成为砂石骨料生产系统全领域定制化解决方案的顶级服务商
          </p>
        </div>
      </div>
    </div>

    <div class="walknav">
      <div class="box">华南机械 > 走进华南</div>
    </div>
    <div class="walkconter">
      <div class="walkconter_nav">
        <ul>
          <li
            v-for="(item, index) in nav"
            :key="index"
            :class="{ active: currentClass == index }"
            @click="toggleTab(index)"
          >
            {{ item }}
          </li>
          <!-- <li @click="">创新精神</li>
                    <li @click="">企业荣誉</li>
                    <li @click="">企业文化</li>
                    <li @click="">华南生产基地</li>
                    <li @click="">华南大事件</li>
                    <li @click="">华南客户</li> -->
        </ul>
      </div>
      <div class="walkconter_conter" v-show="currentindex == 0">
        <div
          data-wow-delay="0.2s"
          class="wow animate__fadeInRight walkconter_conterleft"
        >
          <div>
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-华南机械/矢量智能对象.png"
              alt=""
            />
          </div>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #d22225"
              >华南机械始建于2009年</span
            >，前身为“广州昌顺矿山机械制造有限公司”，于2016年经国家工商总局审核通过正式更名为“华南机械制造有限公司”，2020年通过“国家高新技术企业”。华南机械是一家专注于砂石骨料生产系统解决方案的国家高新技术企业，注册资金5000万，秉承“过硬质量、精湛技术、高效服务”为核心理念，通过砂石生产线设计、设备研发、制造、销售与服务，致力于促进产能最大化；赋能矿山机械行业，展现生产设计的魅力，持续改善矿山企业的生产效率与品质，努力发展成为引领砂石骨料生产系统解决方案的顶级供应商。
          </p>
        </div>
        <div
          data-wow-delay="0.6s"
          class="wow animate__fadeInRight walkconter_conterright"
        >
          <img
            src="../assets/华南官网2.0素材//走进华南/走进华南-华南机械/矩形 579.png"
            alt=""
          />
        </div>
      </div>
      <div class="walkconter_conter" v-show="currentindex == 1">
        <div class="walkconter_conterleft">
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;华南机械拥有高度专业及经验丰富的产品技术团队，研发实力雄厚，高品质的产品质量已经赢得了广大客户的信任与认可。我们始终坚持“诚信务实、客户为本、质量为先、科技创新”的发展目标，满足客户多样化的生产需求，不断进行产品创新与服务改进。
          </p>
        </div>
        <div class="walkconter_conterright">
          <img
            src="../assets/华南官网2.0素材/走进华南/走进华南-创新精神/矩形 579 拷贝.png"
            alt=""
          />
        </div>
      </div>
      <div class="walkconter_conter" v-show="currentindex == 2">
        <div class="walkconter_conter_royu">
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 713 拷贝.png"
              alt=""
            />
          </div>
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 13 拷贝 2.png"
              alt=""
            />
          </div>
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 13 拷贝 3.png"
              alt=""
            />
          </div>
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 13 拷贝 4-1.png"
              alt=""
            />
          </div>
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 13 拷贝 4-2.png"
              alt=""
            />
          </div>
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 13 拷贝 4.png"
              alt=""
            />
          </div>
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 13 拷贝.png"
              alt=""
            />
          </div>
          <div class="walkconter_conter_roy">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-企业荣誉/图层 713.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="walkconter_conter3" v-show="currentindex == 3">
        <div class="walkconter_conter_wenhua">
          <div class="wenhua_title">华南机械企业文化</div>
          <div class="walkconter_conter_wenhua_text">
            <div class="walkconter_conter_wenhua_text2">
              <div class="wenhua_text" style="font-weight: 700">企业愿景：</div>
              <div class="wenhua_text wenhua_text2">
                致力成为引领中国砂石骨料生产系统解决方案的顶级供应商。
              </div>
              <div class="wenhua_text" style="font-weight: 700">企业精神：</div>
              <div class="wenhua_text">了解客户期望，倾力创造效益。</div>
              <div class="wenhua_text">了解客户痛点，完美解决痛点。</div>
              <div class="wenhua_text">了解员工追求，致力共同致富。</div>
              <div class="wenhua_text wenhua_text2">
                了解市场需求，满足客户选择。
              </div>
              <div class="wenhua_text" style="font-weight: 700">经营理念：</div>
              <div class="wenhua_text">品质第一、技术争先</div>
              <div class="wenhua_text wenhua_text2">用心服务、诚信为本。</div>
              <div class="wenhua_text" style="font-weight: 700">企业使命：</div>
              <div class="wenhua_text">
                为客户：为客户创造价值，客户的成功就是我们的成功
              </div>
              <div class="wenhua_text">
                为行业：致力于持续改善矿山生产线效率与品质
              </div>
              <div class="wenhua_text">
                为员工：创造广阔的发展空间，实现自我价值
              </div>
              <div class="wenhua_text">
                为国家：助力中国创造转型，赋能中国工业4.0
              </div>
            </div>
            <div>
              <img
                src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/矩形 579 拷贝 4.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <div>
            <div class="wenhua_title2">华南机械户外活动</div>
            <div class="wenhua_imgbox">
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/户外活动/1.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/户外活动/2.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/户外活动/3.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/户外活动/4.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/户外活动/5.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/户外活动/6.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div class="wenhua_title2">华南机械项目部</div>
            <div class="wenhua_imgbox">
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/项目部/1.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/项目部/2.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/项目部/3.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/项目部/4.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/项目部/5.png"
                  alt=""
                />
              </div>
              <div class="wenhua_img">
                <img
                  src="../assets/华南官网2.0素材/走进华南/走进华南-企业文化/项目部/6.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="walkconter_conter4" v-show="currentindex == 4">
        <div class="jidi">
          <div class="jidi_leftbox">
            <div class="jidi_title">
              精细生产，精湛工艺。 产能效益，重在研发。
            </div>
            <div class="jidi_text">
              生产基地位于佛山南海区夏西工业区，多年来一直专注于矿山设备的研发设计与生产制造，始终为行业客户提供优质的产品、可靠的技术支持及完善的售后服务。我们主要经营适用于矿山破碎生产线的全系列设备产品的研发、生产及销售。
            </div>
          </div>
          <div class="jidi_rightbox">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/1.png"
              alt=""
            />
          </div>
        </div>
        <div class="jidibox2">
          <div>
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/2.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/3.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/4.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/5.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/6.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/7.png"
              alt=""
            />
          </div>
        </div>
        <div style="margin-top: 5%">
          <img
            src="../assets/华南官网2.0素材/走进华南/走进华南-生产基地/矢量智能对象.png"
            alt=""
            style="width: 100%"
          />
        </div>
      </div>
      <div class="walkconter_conter5" v-show="currentindex == 5">
        <div class="shijian">
          <div class="shijian_title">2022年</div>
          <div class="shijian_text">
            <div>星源矿业携手华南机械</div>
            <div>
              规划超大型的砂石骨料生产线项目落地肇庆，投产后将是全国顶尖智能绿色砂石骨料矿山。
            </div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2021年</div>
          <div class="shijian_text">
            <div>紫金企业携手华南机械</div>
            <div>
              打造当地首个绿色智能砂石骨料（碎石制砂洗砂污水处理）综合生产线。
            </div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2020年</div>
          <div class="shijian_text">
            <div>华南机械被认定为“国家高新技术企业”</div>
            <div>华南机械获得“国家高新技术企业”认证。</div>
            <div>
              华南机械中标中交四航局350T/H环保生产线项目，助力大南海石化工业区建设战略。
            </div>
            <div>华南机械连续三年获得“广东省守合同重信用企业”荣誉。</div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2019年</div>
          <div class="shijian_text">
            <div>知识产权管理体系认证通过</div>
            <div>华南机械知识产权管理体系认证通过.</div>
            <div>获得民用无人驾驶航空器经营许可。</div>
            <div>3D扫描及3D交互上线。</div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2018年</div>
          <div class="shijian_text">
            <div>广东股交中心成功挂牌</div>
            <div>华南机械在广东股交中心成功挂牌；股权代码：892335。.</div>
            <div>3D扫描技术研发成功并应用。</div>
            <div>2018 砂石骨料生产， 携手中交四航局建设广连高速。</div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2017年</div>
          <div class="shijian_text">
            <div>3D扫描测试应用</div>
            <div>
              3D扫描测试应用，成为全国同行业唯一 一家实地模拟并落地应
              用的企业。.
            </div>
            <div>同年加入中国砂石协会并任理事会理事单位。</div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2016年</div>
          <div class="shijian_text">
            <div>广州昌顺更名华南机械</div>
            <div>
              “华南机械”历时两年经国家工商总局审核通过，“广州昌顺矿山机械”正式更名为“华南机械制造有限公司”。.
            </div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2014年</div>
          <div class="shijian_text">
            <div>海南省陵水县生产线投产</div>
            <div>
              海南省陵水县（华益矿业）EPC工程投产，每小时高达800吨，成为当年海南省全省单套最大产能的生产线。.
            </div>
          </div>
        </div>
        <div class="shijian">
          <div class="shijian_title">2009年</div>
          <div class="shijian_text">
            <div>广州昌顺矿山机械成立</div>
            <div>
              广州昌顺矿山机械成立（华南机械前身）主营矿山机械设备及配件销售。.
            </div>
          </div>
        </div>
      </div>
      <div class="walkconter_conter6" v-show="currentindex == 6">
        <div class="kehu_text">
          华南机械在中国-立足广州，辐射全国，目标全球。
        </div>
        <div class="kehu_text">
          华南机械在国内，其业务范围覆盖广东省、广西省、海南省、湖南省、湖北省等二十多个省份和城市，由华南机械设计、安装的砂石生产线工程项目达1000余项。
        </div>
        <div style="display: flex">
          <div class="kehu_left">
            <img
              src="../assets/华南官网2.0素材/走进华南/走进华南-华南客户/矢量智能对象.png"
              alt=""
            />
          </div>
          <!-- <div class="kehu_right">
                    <div class="kehu_title">海南省陵水县(华益矿业)800T/H EPC项目</div>
                    <div class="kehu_title">广东省云浮市200TH山泥洗砂制砂生产线</div>
                    <div class="kehu_title">广东省惠州市惠东县300TH综合生产线</div>
                    <div class="kehu_title">广东省揭阳市(中交四航局)350TH环保生产线</div>
                    <div class="kehu_title">广东省梅州市五华县100TH洗砂制砂生产线</div>
                    <div class="kehu_title">广东省深汕合作区(中铁十一局)200TH综合生产线</div>
                    <div class="kehu_title">广东省紫金县400TH洗砂制砂生产线</div>
                    <div class="kehu_title">广西省来宾市武宣县600TH砂石生产线</div>
                    <div class="kehu_title">贵州省定县200TH机制砂生产线</div>
                    <div class="kehu_title">广西省玉林市1000th综合破碎生产线</div>
                </div> -->
        </div>
      </div>
    </div>
    <FTrecord data-wow-delay="0.2s" class="wow animate__fadeInUp"></FTrecord>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import FTrecord from "../components/FTrecord.vue";
import NewsInquiry from "../components/NewsInquiry.vue";
import callme from "../components/callme.vue";
import "wowjs/css/libs/animate.css";
import { WOW } from "wowjs";
export default {
  components: {
    Nav: Nav,
    // 结尾
    FTrecord: FTrecord,
    // 新闻资讯
    NewsInquiry: NewsInquiry,
    callme: callme,
  },
  data() {
    return {
      nav: [
        "华南机械",
        "创新精神",
        "企业荣誉",
        "企业文化",
        "华南生产基地",
        "华南大事件",
        "华南客户",
      ],
      currentClass: "",
      currentindex: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      new WOW().init();
    });
  },
  methods: {
    toggleTab(e) {
      console.log(e);
      this.currentindex = e;
      this.currentClass = e;
    },
  },
};
</script>

<style scoped>
.walkimg {
  width: 100vw;
  height: 260px;
  background-image: url(../assets/华南官网2.0素材/走进华南/矩形\ 3.png);
  background-size: 100% 100%;
}
.box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.boxtext {
  width: 50%;
  height: 30%;
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
}
.P1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  letter-spacing: 2.4px;
}
.P2 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 25px;
}
.walknav {
  width: 100vw;
  height: 50px;
  background-color: #f4f4f4;
}
.box {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 50px;
}

.walkconter {
  width: 60%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
}

.walkconter_nav ul {
  list-style: none;
  display: flex;
  margin-bottom: 50px;
}

.walkconter_nav ul li {
  list-style: none;
  /* flex: 1; */
  text-align: center;
  width: 162px;
  height: 66px;
  border: 1px solid #666666;
  border-radius: 6px;
  line-height: 66px;
  margin: 1%;
  cursor: pointer;
}
.active {
  background-color: #cf000e;
  color: #ffffff;
  border-color: #ffffff;
}
.walkconter_conter {
  display: flex;
}

.walkconter_conterleft {
  width: 50%;
}

.walkconter_conterleft img {
  width: 100%;
}

.walkconter_conterleft p {
  font-size: 14px;
  line-height: 24px;
}

.walkconter_conterright {
  width: 50%;
  margin-left: 5%;
}

.walkconter_conterright img {
  width: 100%;
}

.walkconter_conter_royu {
  display: flex;
  flex-wrap: wrap;
}
.walkconter_conter_roy {
  width: 25%;
}
.walkconter_conter_roy img {
  width: 100%;
}
.wenhua_title {
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed #cccc;
  padding-bottom: 20px;
  margin-bottom: 53px;
}
.walkconter_conter_wenhua_text {
  display: flex;
}
.walkconter_conter_wenhua_text2 {
  width: 50%;
  font-size: 14px;
}
.wenhua_text {
  height: 30px;
}
.wenhua_text2 {
  margin-bottom: 10px;
}
.wenhua_title2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed #cccc;
  padding: 20px;
  margin-bottom: 31px;
}
.wenhua_imgbox {
  display: flex;
  flex-wrap: wrap;
}
.wenhua_img {
  width: 33%;
  margin: 1px;
  overflow: hidden;
}
.wenhua_img img {
  width: 100%;
}
.jidi {
  display: flex;
}
.jidi_leftbox {
  width: 50%;
  padding-right: 3%;
}
.jidi_rightbox {
  width: 50%;
}
.jidi_rightbox img {
  width: 100%;
}
.jidi_title {
  width: 100%;
  height: 38px;
  line-height: 38px;
  background-color: #cf000e;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}
.jidi_text {
  font-size: 14px;
  line-height: 30px;
}
.jidibox2 {
  display: flex;
  flex-wrap: wrap;
}
.jidibox2 div {
  width: 33%;
  margin: 1px;
}
.jidibox2 div img {
  width: 100%;
  height: 100%;
}
.walkconter_conter5 {
}
.shijian {
  display: flex;
  padding-top: 3%;
  padding-bottom: 3%;
  border-bottom: 1px dashed #666666;
  align-items: center;
}
.shijian_title {
  font-size: 30px;
  color: #a31306;
  width: 10%;
}
.shijian_text {
  width: 80%;
  padding-left: 2%;
  border-left: 1px solid #666666;
}
.kehu_title {
  line-height: 50px;
}
.kehu_left {
  width: 100%;
}
.kehu_left img {
  width: 100%;
}
.kehu_right {
  margin-left: 5%;
}
.kehu_text {
  line-height: 50px;
}
@media screen and (max-width: 821px) {
  .walkimg {
    height: 120px;
  }
  .walkimg .box {
    width: 95%;
  }
  .boxtext {
    width: 100%;
  }
  .boxtext .P1 {
    text-align: center;
  }
  .boxtext .P2 {
    display: none;
  }
  .walknav .box {
    width: 95%;
  }

  .walkconter {
    width: 95%;
  }
  .walkconter .box1 .right_box p {
    font-size: 12px;
  }
  .walkconter_conter {
    flex-direction: column;
  }
  .walkconter_conterleft {
    width: 100%;
  }
  .walkconter_conterright {
    width: 100%;
    margin-left: 0;
  }
  .walkconter_conterright img {
    width: 100%;
  }
  .walkconter_nav ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .walkconter_nav ul li {
    width: 22%;
    font-size: 12px;
  }
  .walkconter_conter_wenhua_text {
    flex-direction: column;
  }
  .walkconter_conter_wenhua_text2 {
    width: 100%;
  }
  .wenhua_imgbox {
    justify-content: space-between;
  }
  .wenhua_img {
    width: 49%;
  }
  .jidi {
    flex-direction: column;
    align-items: center;
  }
  .jidi_leftbox {
    width: 100%;
  }
  .jidibox2 div {
    width: 49%;
  }
  .shijian_title {
    font-size: 14px;
    width: 15%;
  }
  .shijian_text {
    font-size: 12px;
  }
}
.active {
  background-color: #cf000e;
  color: #ffffff;
  border: 0px;
}
</style>
